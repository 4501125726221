import React, { useMemo, useEffect, useContext } from 'react';
import UserContext from 'app/context/UserContext';
import { useParams } from 'react-router-dom';
import useRequest from 'app/hooks/useRequest';
import { getReferrals } from 'app/api/MemberService';
import { Card, Table } from 'react-bootstrap';
import Thead from './thead/Thead';
import TableRow from './table-row/TableRow';

export default function ReferralsTable() {
  const params = useParams();
  const { user } = useContext(UserContext);
  const { response, sendData } = useRequest();
  const columns = [
    'Username',
    'Email',
    'Level',
    'Amount',
    'Paid Date',
    'Receipt No.',
    'Status'
  ];

  useEffect(() => {
    sendResponse();
  }, [params.id]);

  const referrals = useMemo(() => {
    return response?.data ? response?.data : [];
  }, [response]);

  function sendResponse() {
    const config = getReferrals(user.token, params.id);
    sendData(config);
  }

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5>Referrals</h5>
      </Card.Header>
      <Card.Body className="border-top">
        {referrals.length ? (
          <Table responsive striped hover className="fs--1">
            {referrals.length ? <Thead columns={columns} /> : ''}
            <tbody>
              {referrals.map(item => (
                <TableRow key={item.id} member={item} />
              ))}
            </tbody>
          </Table>
        ) : (
          'No referrals yet.'
        )}
      </Card.Body>
    </Card>
  );
}
