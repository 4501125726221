import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import TodaysNotificationForm from './TodaysNotificationForm';

export default function TodaysNotification() {
  return (
    <Card>
      <Card.Header className="bg-light">
        <h6 className="mb-0">Custom Text Widget</h6>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <TodaysNotificationForm />
          </Col>
          <Col md={6}>
            <h5>Examples</h5>
            {'<h5 class="text-warning">Title</h5>'}
            <br />
            {'<p class="fs--1 mb-1">Paragraph</p>'}
            <br />
            {'<b>bold text</b>'}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
