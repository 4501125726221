import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Button, Form, Spinner } from 'react-bootstrap';
import { getOption, updateOption } from 'app/api/OptionsService';
import UserContext from 'app/context/UserContext';

export default function TodaysNotificationForm() {
  const { user } = useContext(UserContext);
  const [notification, setNotification] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = e => {
    setNotification(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .request(updateOption(user, 'dash_todays_notification', notification))
      .then(() => {
        toast.success(`Data saved`, {
          theme: 'colored'
        });
      })
      .catch(() => {
        // console.log('catch');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchData = () => {
    setIsLoading(true);
    axios
      .request(getOption(user, 'dash_todays_notification'))
      .then(response => {
        setNotification(response.data.optionValue);
      })
      .catch(() => {
        // console.log('catch');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Form as={Form} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="form.textarea">
        <Form.Control
          as="textarea"
          rows={5}
          name="notification"
          value={notification}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group>
        <Button type="submit" disabled={!notification}>
          Save
        </Button>
        {isLoading && (
          <Spinner
            variant="primary"
            animation="grow"
            as="span"
            size="sm"
            className="ms-2"
          />
        )}
      </Form.Group>
    </Form>
  );
}
