import { urlBaseApi } from '../consts';

const urlBase = urlBaseApi + '/contents';

export const list = token => {
  return {
    url: `${urlBase}/`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const remove = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const create = (token, body) => {
  return {
    url: `${urlBase}/`,
    method: 'POST',
    data: body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const one = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};
