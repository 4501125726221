import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { getOption, updateOption } from 'app/api/PublicOptionsService';
import useRequest from 'app/hooks/useRequest';
import UserContext from 'app/context/UserContext';
import { toast } from 'react-toastify';
import Divider from 'components/common/Divider';

export default function InvestmentForm() {
  const { user } = useContext(UserContext);
  const { data, fetch } = useRequest();
  const { response, send, loading } = useRequest();

  const [investment, setInvestment] = useState({
    title: '...',
    subTitle: '...',
    sections: [
      {
        title: '...',
        description: '...'
      },
      {
        title: '...',
        description: '...'
      },
      {
        title: '...',
        description: '...'
      }
    ]
  });

  const handleSubmit = e => {
    e.preventDefault();

    send(
      updateOption(user.token, 'landing_investment', JSON.stringify(investment))
    );
  };

  useEffect(() => {
    fetch(getOption('landing_investment'));
  }, []);

  useEffect(() => {
    data && setInvestment(JSON.parse(data.optionValue));
  }, [data]);

  useEffect(() => {
    if (response && response.status === 200) {
      toast.success('Saved successfully', {
        theme: 'colored'
      });
    }
  }, [response]);

  const renderSection = (section, idx) => {
    return (
      <Col xl={4}>
        <Divider className="mt-5 mb-3">
          <b>Section {idx + 1}</b>
        </Divider>
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={section.title}
            name="title"
            placeholder="..."
            required
            onChange={e => {
              investment.sections[idx] = {
                title: e.target.value,
                description: investment.sections[idx].description
              };

              setInvestment({
                ...investment
              });
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="form.textarea">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            name="subTitle"
            value={section.description}
            placeholder="..."
            onChange={e => {
              investment.sections[idx] = {
                title: investment.sections[idx].title,
                description: e.target.value
              };

              setInvestment({
                ...investment
              });
            }}
          />
        </Form.Group>
      </Col>
    );
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xl={12}>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={investment?.title}
                name="title"
                placeholder="..."
                required
                onChange={e => {
                  setInvestment({ ...investment, title: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="form.textarea">
              <Form.Label>Subtitle</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="subTitle"
                value={investment?.subTitle}
                placeholder="..."
                onChange={e => {
                  setInvestment({ ...investment, subTitle: e.target.value });
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* <Row>{investment && renderSection(investment.sections[0], 0)}</Row> */}
        <Row>
          {investment.sections.map((section, idx) =>
            renderSection(section, idx)
          )}
        </Row>

        <Divider className="my-4"></Divider>

        <Button
          variant="primary"
          type="submit"
          name="common[submit]"
          disabled={loading}
        >
          Save
        </Button>
        {loading && (
          <Spinner
            animation="grow"
            variant="primary"
            size="sm"
            className="ms-2"
          />
        )}
      </Form>
    </>
  );
}
