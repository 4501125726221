import React from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/common/PageHeader';
import TodaysNotification from './todays-notification/TodaysNotification';

export default function DashboardSettings() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Dashboard Settings | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>
      <PageHeader title="Dashboard Settings" className="mb-3"></PageHeader>
      <TodaysNotification />
    </>
  );
}
