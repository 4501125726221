import React from 'react';
import PageHeader from 'components/common/PageHeader';
import WithdrawalsTable from './components/WithdrawalsTable';
import Helmet from 'react-helmet';
import { Card } from 'react-bootstrap';

export default function Withdrawals() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Withdrawals | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <PageHeader title="Withdrawals" className="mb-3"></PageHeader>

      <Card className="mb-3">
        <Card.Body>
          <WithdrawalsTable />
        </Card.Body>
      </Card>
    </>
  );
}
