import { urlBaseApi } from '../consts';

const urlBase = urlBaseApi + '/seminars';

export const list = token => {
  return {
    url: `${urlBase}/`,
    method: 'GET',
    params: {
      all: true
    },
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const remove = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const create = (token, body) => {
  return {
    url: `${urlBase}/`,
    method: 'POST',
    data: body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const update = (token, id, body) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'PUT',
    data: body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const one = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const listSeminarSectionsById = (token, id) => {
  return {
    url: `${urlBase}/${id}/sections`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const updateOrderAllItem = (token, id, body) => {
  return {
    url: `${urlBase}/${id}/section`,
    method: 'PUT',
    data: body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};
