import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Tooltip } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WalletAdress = ({ wallet }) => {
  const iconCopy = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  function toogleTooltip(toggle) {
    setShowTooltip(toggle);

    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  }

  function short() {
    return wallet.slice(0, 7) + '...' + wallet.slice(-5);
  }

  return (
    <>
      <span style={{ fontFamily: 'monospace' }}>{short(wallet)}</span>

      <CopyToClipboard
        style={{ position: 'relative', marginLeft: '10px' }}
        text={wallet}
      >
        <span ref={iconCopy} onClick={() => toogleTooltip(!showTooltip)}>
          <FontAwesomeIcon
            icon="copy"
            sz="xs"
            className="fs-0 text-500 cursor-pointer"
          ></FontAwesomeIcon>
        </span>
      </CopyToClipboard>

      <Overlay target={iconCopy.current} show={showTooltip} placement="top">
        {props => (
          <Tooltip id="overlay-example" {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

WalletAdress.propTypes = {
  wallet: PropTypes.string
};

export default WalletAdress;
