import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Date from 'app/components/common/Date';
import BadgeMemberStatus from 'app/components/common/BadgeMemberStatus';

const TableRow = ({ member }) => {
  return (
    <tr>
      <td className="text-nowrap">
        <Link to={`/dashboard/members/${member.id}`}>{member.name}</Link>
      </td>
      <td className="text-nowrap">{member.email}</td>
      <td className="text-nowrap">
        {member?.MemberSubscription?.membershipLevel}
      </td>
      <td className="text-nowrap">{member?.MemberSubscription?.amount}</td>
      <td className="text-nowrap">
        {member?.MemberSubscription?.startedAt ? (
          <Date date={member?.MemberSubscription?.startedAt} format="human" />
        ) : null}
      </td>
      <td className="text-nowrap">
        {member?.MemberSubscription?.receiptNumber}
      </td>
      <td>
        <BadgeMemberStatus status={member.status} />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  member: PropTypes.any
};

export default TableRow;
