import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import MainForm from './MainForm';
import QrForm from './QrForm';

export default function Common() {
  return (
    <>
      <Row>
        <Col lg={6} className="mb-3">
          <Card className="h-100">
            <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
              <h5 className="mb-0">Common</h5>
            </Card.Header>
            <Card.Body>
              <MainForm />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="mb-3">
          <Card className="h-100">
            <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
              <h5 className="mb-0">QR code</h5>
            </Card.Header>
            <Card.Body>
              <QrForm />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
