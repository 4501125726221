import { urlBaseApi as apiUrl } from '../consts';

const endpointUrl = apiUrl + '/memberships';

export const getAll = token => {
  return {
    url: `${endpointUrl}/levels`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getOne = (token, levelId) => {
  return {
    url: `${endpointUrl}/levels/${levelId}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const putOne = (token, level) => {
  return {
    url: `${endpointUrl}/levels/${level.id}`,
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: level
  };
};
