import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'react-bootstrap';
import moment from 'moment';

const TableRow = ({ num, content, handlerDelete, handlerPreview }) => {
  return (
    <tr>
      <td>{num + 1}</td>
      <td className="text-nowrap">{content?.title}</td>
      <td className="text-nowrap">{content?.views}</td>
      <td className="text-nowrap">
        {content?.createdAt
          ? moment(content?.createdAt).format('DD-MM-YYYY')
          : ''}
      </td>
      <td className="text-nowrap">{content?.type}</td>
      <td className="text-end">
        <ButtonGroup size="sm">
          <Button
            variant="link"
            className="me-2"
            onClick={() => handlerPreview(content.id)}
          >
            Preview
          </Button>
          <Button
            variant="link"
            className="text-danger"
            onClick={() => handlerDelete(content.id)}
          >
            Delete
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  num: PropTypes.number,
  content: PropTypes.any,
  handlerDelete: PropTypes.func,
  handlerPreview: PropTypes.func
};

export default TableRow;
