import React, { useMemo, useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import useRequest from 'app/hooks/useRequest';
import { useParams } from 'react-router-dom';
import UserContext from 'app/context/UserContext';
import SeminarSectionUpdateForm from './SeminarSectionUpdateForm';
import { one, listMediaItems } from 'app/api/SeminarSectionService';

const SeminarSectionUpdate = () => {
  const { sectionId } = useParams();
  const { user } = useContext(UserContext);
  const {
    response: responseSeminarSelection,
    sendData: sendDataSeminarSelection
  } = useRequest();
  const { response: responseItemMedia, sendData: sendDataItemMedia } =
    useRequest();

  useEffect(() => {
    sendResponseSeminar();
    sendResponseItemMedia();
  }, [sectionId]);

  const seminarSelection = useMemo(() => {
    return responseSeminarSelection?.data ? responseSeminarSelection?.data : [];
  }, [responseSeminarSelection]);

  function sendResponseSeminar() {
    const config = one(user.token, sectionId);
    sendDataSeminarSelection(config);
  }

  const itemMedia = useMemo(() => {
    return responseItemMedia?.data ? responseItemMedia?.data : [];
  }, [responseItemMedia]);

  function sendResponseItemMedia() {
    const config = listMediaItems(user.token, sectionId);
    sendDataItemMedia(config);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Seminar Section Update ${seminarSelection.title} | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <SeminarSectionUpdateForm
        seminarOld={seminarSelection}
        seminarMediaItemOld={itemMedia}
      />
    </>
  );
};

export default SeminarSectionUpdate;
