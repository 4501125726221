import { Navigate, Route, Routes } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import AppContext from 'context/Context';
import AuthSimpleLayout from 'app/layouts/AuthSimpleLayout';
import { CloseButton } from 'components/common/Toast';
import DashboardLayout from './DashboardLayout';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import ErrorLayout from 'layouts/ErrorLayout';
import Login from 'app/pages/auth/login/Login';
import Member from 'app/pages/member/Member';
import Members from 'app/pages/members/Members';
import Contents from 'app/pages/contents/Contents';
import Withdrawals from 'app/pages/withdrawals/Withdrawals';
import DashboardSettings from 'app/pages/settings/dashboard/DashboardSettings';
import is from 'is_js';
import MembershipSettings from 'app/pages/settings/memberships/Memberships';
import LevelsSettings from 'app/pages/settings/memberships/levels/Levels';
import LevelSettings from 'app/pages/settings/memberships/level/Level';
import LandingSettings from 'app/pages/settings/landing/Landing';
import MembershipsDesc from 'app/pages/settings/memberships/desc/MembershipsDesc';
import Seminars from 'app/pages/seminars/list/Seminars';
import SeminarCreate from 'app/pages/seminars/create/SeminarCreate';
import SeminarUpdate from 'app/pages/seminars/update/SeminarUpdate';
import SeminarSectionCreate from 'app/pages/seminarSections/create/SeminarSectionCreate';
import SeminarSectionUpdate from 'app/pages/seminarSections/update/SeminarSectionUpdate';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        {/* -------------- Authentication --------------------------- */}

        <Route element={<AuthSimpleLayout />}>
          <Route path="/" element={<Login />} />
        </Route>

        {/* -------------- Dashboard -------------- ------------------ */}

        <Route element={<DashboardLayout />}>
          <Route
            path="/dashboard"
            element={<Navigate to="/dashboard/members" replace />}
          />
          <Route path="/dashboard/members" element={<Members />} />
          <Route path="/dashboard/members/:id" element={<Member />} />

          <Route path="/dashboard/contents" element={<Contents />} />
          <Route path="/dashboard/withdrawals" element={<Withdrawals />} />

          <Route path="/dashboard/seminars" element={<Seminars />} />
          <Route
            path="/dashboard/seminars/create"
            element={<SeminarCreate />}
          />
          <Route
            path="/dashboard/seminars/:id/edit"
            element={<SeminarUpdate />}
          />

          <Route
            path="/dashboard/seminars/:seminarId/section/create"
            element={<SeminarSectionCreate />}
          />

          <Route
            path="/dashboard/seminars/:seminarId/section/:sectionId/edit"
            element={<SeminarSectionUpdate />}
          />

          <Route
            path="/dashboard/settings/dashboard"
            element={<DashboardSettings />}
          />
          <Route
            path="/dashboard/settings/landing"
            element={<LandingSettings />}
          />
          <Route
            path="/dashboard/settings/memberships-common"
            element={<MembershipSettings />}
          />
          <Route
            path="/dashboard/settings/memberships-levels"
            element={<LevelsSettings />}
          />
          <Route
            path="/dashboard/settings/membership-levels/:id"
            element={<LevelSettings />}
          />
          <Route
            path="/dashboard/settings/memberships-desc"
            element={<MembershipsDesc />}
          />
        </Route>

        {/* -------------- Errors ----------------------------------- */}

        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_RIGHT}
      />
    </>
  );
};

export default Layout;
