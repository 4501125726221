import { Button, Form, Spinner } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';

import { login as APILogin } from 'app/api/AdminService';
import PropTypes from 'prop-types';
import UserContext from 'app/context/UserContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AlertError from 'app/components/common/AlertError';
import FeedbackError from 'app/components/common/FeedbackError';

const LoginForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: [],
    password: [],
    general: []
  });

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, []);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    setErrors({
      email: [],
      password: [],
      general: []
    });
    setLoading(true);

    axios
      .request(APILogin(formData.email, formData.password))
      .then(response => {
        setUser(response.data);
        navigate('/dashboard');
        navigate('/dashboard');
      })
      .catch(error => {
        const errors = {
          email: [],
          password: [],
          general: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
          isInvalid={errors.email.length}
        />
        <FeedbackError className="small" errors={errors} type="email" />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
          isInvalid={errors.password.length}
        />
        <FeedbackError errors={errors} type="password" />
      </Form.Group>

      <Form.Group>
        {!loading ? (
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.email || !formData.password}
          >
            Log in
          </Button>
        ) : (
          <Button variant="primary" className="w-100" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span>Loading...</span>
          </Button>
        )}

        <Form.Group className="mt-3">
          <AlertError errors={errors} type="general" />
        </Form.Group>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
