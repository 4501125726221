import React, { useContext, useState } from 'react';
import { Col, Card, Form, Row, Button } from 'react-bootstrap';
import PageHeader from 'app/components/header/PageHeader';
import UserContext from 'app/context/UserContext';
import { create } from 'app/api/SeminarsService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FeedbackError from 'app/components/common/FeedbackError';

const selectOptions = [
  {
    id: 0,
    value: 'active'
  },
  {
    id: 1,
    value: 'archived'
  }
];

const SeminarCreateForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [seminar, setSeminar] = useState({
    id: 0,
    title: '',
    status: 'active',
    titleBlock: '',
    descriptionBlock: ''
  });
  const { user } = useContext(UserContext);
  const [errors, setErrors] = useState({
    title: [],
    titleBlock: [],
    descriptionBlock: []
  });

  const handlerSelected = e => {
    setSeminar({ ...seminar, status: e.target.value });
  };

  const handlerSave = e => {
    e.preventDefault();

    setIsLoading(true);
    let data = new FormData();

    Object.getOwnPropertyNames(seminar).forEach(value => {
      data.set(value, seminar[value]);
    });

    axios
      .request(create(user.token, data))
      .then(data => {
        toast.success('Seminar created.', {
          theme: 'colored'
        });
        navigate(`/dashboard/seminars/${data.data.id}/edit`);
      })
      .catch(error => {
        const errors = {
          title: [],
          titleBlock: [],
          descriptionBlock: []
        };

        error.response.data.errors.map(item => {
          console.log(item);
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageHeader
        col={{ xs: 12 }}
        title={'Create ' + (seminar?.title ? seminar.title : 'New seminar')}
        description=""
        className="mb-3"
      ></PageHeader>
      <Row className="g-1 mb-4">
        <Card className="h-100">
          <Card.Body className="fs--1 fs-lg-0">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Status</Form.Label>
              <Form.Select
                onChange={e => handlerSelected(e)}
                defaultValue={selectOptions[0].value}
              >
                {selectOptions.map(selectOption => (
                  <option value={selectOption.value} key={selectOption.id}>
                    {selectOption.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                onChange={e => {
                  setSeminar({
                    ...seminar,
                    title: e.target.value
                  });
                }}
                placeholder="New seminar"
                isInvalid={errors.title.length}
              />
              <FeedbackError errors={errors} type="title" />
            </Form.Group>
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-3">
        <Card className="h-100 mb-3">
          <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
            <h5 className="mb-0">Text block</h5>
          </Card.Header>
          <Card.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title block</Form.Label>
              <Form.Control
                type="text"
                value={seminar.titleBlock}
                onChange={e => {
                  setSeminar({
                    ...seminar,
                    titleBlock: e.target.value
                  });
                }}
                isInvalid={errors.titleBlock.length}
              />
              <FeedbackError errors={errors} type="titleBlock" />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={seminar.descriptionBlock}
                onChange={e => {
                  setSeminar({
                    ...seminar,
                    descriptionBlock: e.target.value
                  });
                }}
                isInvalid={errors.descriptionBlock.length}
              />
              <FeedbackError errors={errors} type="descriptionBlock" />
            </Form.Group>
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-3">
        <Card className="h-100 mb-3">
          <Card.Body>
            <Row>
              <Col>
                <Button
                  type="submit"
                  onClick={e => handlerSave(e)}
                  variant="success"
                  disabled={isLoading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default SeminarCreateForm;
