import React from 'react';
import Helmet from 'react-helmet';
import SeminarSectionCreateForm from './SeminarSectionCreateForm';

const SeminarSectionCreate = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Seminar Section Create | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <SeminarSectionCreateForm />
    </>
  );
};

export default SeminarSectionCreate;
