import { Col, Card, Table, Button } from 'react-bootstrap';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Thead from './thead/Thead';
import useRequest from 'app/hooks/useRequest';
import UserContext from 'app/context/UserContext';
import { list, remove } from 'app/api/ContentService';
import TableRow from './table-row/TableRow';
import ModalUpload from './modal-upload/ModalUpload';
import ModalPreview from './modal-preview/ModalPreview';
import PageHeader from 'app/components/header/PageHeader';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function ContentTable() {
  const columns = ['#', 'Title', 'Views', 'Date added', 'Type', 'Actions'];
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [previewContetentId, setPreviewContetentId] = useState(null);
  const { user } = useContext(UserContext);
  const { response, sendData } = useRequest();

  useEffect(() => {
    sendResponse();
  }, []);

  function sendResponse() {
    const config = list(user.token);
    sendData(config);
  }

  const contents = useMemo(() => {
    return response?.data ? response?.data : [];
  }, [response]);

  function handlerDelete(id) {
    axios
      .request(remove(user.token, id))
      .then(() => {
        toast.success('Content deleted', {
          theme: 'colored'
        });
        sendResponse();
      })
      .catch(() => {
        toast.error('Error');
      });
  }

  function handlerShowModalUpload() {
    setShowModalUpload(!showModalUpload);
  }

  function handlerShowModalPreview(id) {
    setShowModalPreview(!showModalPreview);
    setPreviewContetentId(id);
  }

  return (
    <>
      <PageHeader
        col={{ xs: 6 }}
        title="Content"
        description=""
        className="mb-3"
      >
        <Col className="d-flex justify-content-end">
          <Button
            variant="primary"
            onClick={() => handlerShowModalUpload()}
            disable={showModalUpload}
            size="sm"
          >
            Add
          </Button>
        </Col>
      </PageHeader>

      <Card>
        <Card.Body className="fs--1 fs-lg-0">
          <Table responsive striped hover>
            <Thead columns={columns} />
            <tbody>
              {contents.map((item, idx) => (
                <TableRow
                  key={item.id}
                  num={idx}
                  content={item}
                  handlerDelete={handlerDelete}
                  handlerPreview={handlerShowModalPreview}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <ModalUpload
        showModal={showModalUpload}
        handlerShowModal={handlerShowModalUpload}
        updateTable={sendResponse}
      />
      <ModalPreview
        showModal={showModalPreview}
        handlerShowModal={handlerShowModalPreview}
        id={previewContetentId}
      />
    </>
  );
}
