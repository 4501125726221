import { getOption, updateOption } from 'app/api/OptionsService';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function MembershipsDesc() {
  const { user } = useContext(UserContext);
  const [desc, setDesc] = useState({
    title: '...',
    content: '...'
  });

  // fetch data
  const { data, fetch } = useRequest();

  useEffect(() => {
    fetch(getOption(user, 'membership_desc'));
  }, []);

  useEffect(() => {
    if (!data) return null;
    setDesc(JSON.parse(data.optionValue));
  }, [data]);

  // update data
  const { response, sendData: putOption, loading } = useRequest();

  useEffect(() => {
    if (response && response.status === 200) {
      toast.success('Common settings saved successfully', {
        theme: 'colored'
      });
    }
  }, [response]);

  const handleSubmit = e => {
    e.preventDefault();
    putOption(updateOption(user, 'membership_desc', JSON.stringify(desc)));
  };

  return (
    <>
      <Row>
        <Col lg={6} className="mb-3">
          <Card className="h-100">
            <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
              <h5 className="mb-0">Membership Description</h5>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={desc.title}
                    name="title"
                    placeholder="..."
                    required
                    onChange={e => {
                      setDesc({ ...desc, title: e.target.value });
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    type="textarea"
                    value={desc.content}
                    name="content"
                    placeholder="..."
                    required
                    onChange={e => {
                      setDesc({ ...desc, content: e.target.value });
                    }}
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  name="submit"
                  disabled={loading}
                >
                  Save
                </Button>
                {loading && (
                  <Spinner
                    animation="grow"
                    variant="primary"
                    size="sm"
                    className="ms-2"
                  />
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
