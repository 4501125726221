import React from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/common/PageHeader';
import Investment from './investment/Investment';

export default function Landing() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | Landing</title>
      </Helmet>
      <PageHeader title="Landing Settings" className="mb-3"></PageHeader>
      <Investment />
    </>
  );
}
