import { urlBaseApi as apiUrl } from '../consts';

const endpointUrl = apiUrl + '/options/public';

export const getOption = optionName => {
  return {
    url: `${endpointUrl}/${optionName}`,
    method: 'GET'
  };
};

export const updateOption = (token, optionName, optionValue) => {
  return {
    url: `${endpointUrl}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: {
      optionName: optionName,
      optionValue: optionValue
    }
  };
};
