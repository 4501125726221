/* eslint-disable react/prop-types */
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

export const WithdrawalsTableFooter = ({
  page,
  pageSize,
  pageIndex,
  rowCount,
  setPageSize,
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  rowInfo,
  rowsPerPageSelection,
  navButtons,
  rowsPerPageOptions = [5, 10, 15]
}) => {
  return (
    <>
      <Row>
        <Col>
          <Flex alignItems="center" className="fs--1">
            {rowInfo && (
              <p className="mb-0">
                <span className="me-2">
                  {pageSize * pageIndex + 1} to{' '}
                  {pageSize * pageIndex + page.length} of {rowCount}
                </span>
              </p>
            )}
            {rowsPerPageSelection && (
              <>
                <p className="mb-0 mx-2">Rows per page:</p>
                <Form.Select
                  size="sm"
                  className="w-auto"
                  onChange={e => setPageSize(e.target.value)}
                  defaultValue={pageSize}
                >
                  {rowsPerPageOptions.map(value => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
              </>
            )}
          </Flex>
        </Col>
        <Col xs="12" sm="6" className="mt-5 mt-sm-0">
          {navButtons && (
            <Flex className="justify-content-end">
              <Button
                size="sm"
                variant={canPreviousPage ? 'primary' : 'light'}
                onClick={() => previousPage()}
                className={classNames({ disabled: !canPreviousPage })}
              >
                Previous
              </Button>
              <Button
                size="sm"
                variant={canNextPage ? 'primary' : 'light'}
                className={classNames('px-4 ms-2', {
                  disabled: !canNextPage
                })}
                onClick={() => nextPage()}
              >
                Next
              </Button>
            </Flex>
          )}
        </Col>
      </Row>
    </>
  );
};

export default WithdrawalsTableFooter;
