import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const Date = ({ date, format = 'standard' }) => {
  switch (format) {
    case 'human':
      return <>{moment(date).format('ll')}</>;
    case 'human-time':
      return <>{moment(date).format('lll')}</>;
    case 'standard':
      return <>{moment(date).format('DD-MM-YYYY')}</>;
  }
};

Date.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string
};

export default Date;
