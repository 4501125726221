import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ListItem({ level }) {
  return (
    <Col lg={4}>
      <Card className="mb-3 h-100">
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h4 className="text-uppercase">{level.title}</h4>
            <Link to={`/dashboard/settings/membership-levels/${level.id}`}>
              <Button variant="primary">Edit</Button>
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="fw-bold text-400">price</div>
          <div className="fs-1">{level.price} USDT</div>
          <hr className="my-3" />

          <div className="fw-bold text-400">tagline</div>
          <div className="fs-1">{level.subTitle}</div>
          <hr className="my-3" />

          <div className="fw-bold text-400">features title</div>
          <div className="fs-1">{level.featuresTitle}</div>
          <hr className="my-3" />

          <div className="fw-bold text-400">features list</div>
          <ul className="mt-2">
            {level.featuresList.map(feature => (
              <li key={feature.id} className="fs-0">
                {feature.title}
              </li>
            ))}
          </ul>
        </Card.Body>
      </Card>
    </Col>
  );
}

ListItem.propTypes = {
  level: PropTypes.object
};
