import Flex from 'components/common/Flex';
import { Helmet } from 'react-helmet';
import LoginForm from './LoginForm';
import React from 'react';

export default function Login() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Login | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Log in</h5>
      </Flex>
      <LoginForm />
    </>
  );
}
