import { urlBaseApi } from '../consts';

const urlBase = urlBaseApi + '/withdrawal-requests';

export const getWithdrawals = token => {
  return {
    url: `${urlBase}/`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const putWithdrawal = (token, withdrawalData) => {
  return {
    url: `${urlBase}/${withdrawalData.id}`,
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: { ...withdrawalData }
  };
};
