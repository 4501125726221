import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { getOption, updateOption } from 'app/api/OptionsService';
import useRequest from 'app/hooks/useRequest';
import UserContext from 'app/context/UserContext';
import { toast } from 'react-toastify';

export default function MainForm() {
  const { user } = useContext(UserContext);
  const [common, setCommon] = useState({
    wallet: 'xxx',
    network: 'xxx'
  });
  const { data, fetch } = useRequest();
  const { response, sendData: putOption, loading } = useRequest();

  const handleSubmit = e => {
    e.preventDefault();
    putOption(updateOption(user, 'membership_common', JSON.stringify(common)));
  };

  useEffect(() => {
    fetch(getOption(user, 'membership_common'));
  }, []);

  useEffect(() => {
    if (!data) return null;
    setCommon(JSON.parse(data.optionValue));
  }, [data]);

  useEffect(() => {
    if (response && response.status === 200) {
      toast.success('Common settings saved successfully', {
        theme: 'colored'
      });
    }
  }, [response]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formGroupName">
        <Form.Label>Wallet</Form.Label>
        <Form.Control
          type="text"
          value={common?.wallet}
          name="common[wallet]"
          placeholder="xxx"
          required
          onChange={e => {
            setCommon({ ...common, wallet: e.target.value });
          }}
        />

        <Form.Label>Network</Form.Label>
        <Form.Control
          type="text"
          value={common?.network}
          name="common[network]"
          placeholder="xxx"
          required
          onChange={e => {
            setCommon({ ...common, network: e.target.value });
          }}
        />
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
        name="common[submit]"
        disabled={loading}
      >
        Save
      </Button>
      {loading && (
        <Spinner
          animation="grow"
          variant="primary"
          size="sm"
          className="ms-2"
        />
      )}
    </Form>
  );
}
