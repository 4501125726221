import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';

import AppContext from 'context/Context';
import NavbarTop from 'app/components/navbar/NavbarTop';
import ProductProvider from 'components/app/e-commerce/ProductProvider';
import UserContext from 'app/context/UserContext';
import classNames from 'classnames';
import NavbarVertical from 'app/components/navbar/vertical/NavbarVertical';

const DashboardLayout = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, []);

  const { pathname } = useLocation();
  const isKanban = pathname.includes('kanban');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <ProductProvider>
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
          {/*------ Main Routes ------*/}
          <Outlet />
        </div>
      </ProductProvider>
    </div>
  );
};

export default DashboardLayout;
