import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Col, Card, Form, Row, Button, Spinner } from 'react-bootstrap';
import PageHeader from 'app/components/header/PageHeader';
import UserContext from 'app/context/UserContext';
import { create } from 'app/api/SeminarSectionService';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { ReactSortable } from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useNavigate, Link } from 'react-router-dom';
import CreateMediaItem from './create-media-item/CreateMediaItem';
import { getAll } from 'app/api/MembershipsService';
import useRequest from 'app/hooks/useRequest';
import UpdateMediaItem from './update-media-item/UpdateMediaItem';
import { upload } from 'app/api/UploadsService';
import { creators } from 'app/api/SeminarMediaService';
import Avatar from 'components/common/Avatar';
import FeedbackError from 'app/components/common/FeedbackError';
import AlertError from 'app/components/common/AlertError';

const SeminarSectionCreateForm = () => {
  const { seminarId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [updateMediaItem, setUpdateMediaItem] = useState({});
  const [itemUpdateIndex, setItemUpdateIndex] = useState(0);
  const [seminarMediaItems, setSeminarMediaItems] = useState([]);
  const [showCreateItemModal, setShowCreateItemModal] = useState(false);
  const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);
  const [seminarSection, setSeminarSection] = useState({
    title: '',
    description: '',
    files: [],
    bannerImage: ''
  });
  const [errors, setErrors] = useState({
    title: [],
    description: [],
    files: [],
    bannerImage: [],
    items: []
  });
  const { user } = useContext(UserContext);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      if (!acceptedFiles[0].type.includes('image')) {
        setErrors({
          ...errors,
          bannerImage: [{ msg: 'Not valid type file' }]
        });

        return;
      }

      setErrors({
        ...errors,
        bannerImage: []
      });

      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);

      axios
        .request(upload(user.token, formData))
        .then(response => {
          setSeminarSection({
            ...seminarSection,
            files: acceptedFiles.map(file =>
              Object.assign(file, {
                preview: URL.createObjectURL(file)
              })
            ),
            bannerImage: response.data.relativePath,
            bannerImageFullPath: response.data.path
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  });
  const { response, sendData } = useRequest();

  useEffect(() => {
    sendResponse();
  }, []);

  function sendResponse() {
    const config = getAll(user.token);
    sendData(config);
  }

  const membershipLevels = useMemo(() => {
    return response?.data ? response?.data : [];
  }, [response]);

  const handlerSave = e => {
    e.preventDefault();

    setIsLoading(true);

    axios
      .request(
        create(user.token, {
          title: seminarSection.title,
          description: seminarSection.description,
          bannerImage: seminarSection.bannerImage,
          seminarId: seminarId
        })
      )
      .then(response => {
        let body = seminarMediaItems.map((item, index) => {
          item.order = index;
          item.seminarSectionId = response.data.id;
          return item;
        });

        axios
          .request(creators(user.token, body))
          .then(() => {
            toast.success('Seminar Section with media item created.', {
              theme: 'colored'
            });
            let path = `/dashboard/seminars/${seminarId}/section/${response.data.id}/edit`;
            navigate(path);
          })
          .catch(error => {
            const errors = {
              title: [],
              description: [],
              files: [],
              bannerImage: [],
              items: []
            };

            error.response.data.errors.map(item => {
              errors['items'].push(item);
            });

            setErrors(errors);
          });
      })
      .catch(error => {
        const errors = {
          title: [],
          description: [],
          files: [],
          bannerImage: [],
          items: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlerShowModal = () => {
    setShowCreateItemModal(!showCreateItemModal);
  };

  const handlerAddNewItemSeminarMediaItem = item => {
    setSeminarMediaItems([...seminarMediaItems, item]);
  };

  const handlerShowModalAddItem = () => {
    handlerShowModal();
  };

  const handlerShowModalUpdateItem = () => {
    setShowUpdateItemModal(!showUpdateItemModal);
  };

  const formatAccess = access => {
    const accessTitles = [];

    membershipLevels.forEach(element => {
      if (access.includes(element.id)) {
        accessTitles.push(element.title);
      }
    });

    return accessTitles.join(', ');
  };

  const removeCard = indexRemove => {
    setSeminarMediaItems(
      seminarMediaItems.filter((value, _index) => {
        return _index !== indexRemove;
      })
    );
  };

  const editCard = (index, item) => {
    setUpdateMediaItem(item);
    setItemUpdateIndex(index);
    handlerShowModalUpdateItem();
  };

  const handlerUpdateItemSeminar = (changeIndex, newItem) => {
    setSeminarMediaItems(
      seminarMediaItems.map((value, index) => {
        return index == changeIndex ? newItem : value;
      })
    );
  };

  return (
    <>
      <PageHeader
        col={{ xs: 12 }}
        title={
          'Create ' +
          (seminarSection?.title ? seminarSection.title : 'New Section')
        }
        description=""
        className="mb-3"
      ></PageHeader>
      <Row className="g-1 mb-4">
        <Card className="h-100">
          <Card.Body className="fs--1 fs-lg-0">
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                onChange={e => {
                  setSeminarSection({
                    ...seminarSection,
                    title: e.target.value
                  });
                }}
                placeholder="New Section"
                isInvalid={errors.title.length}
              />
              <FeedbackError errors={errors} type="title" />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={seminarSection.description}
                onChange={e => {
                  setSeminarSection({
                    ...seminarSection,
                    description: e.target.value
                  });
                }}
                isInvalid={errors.description.length}
              />
              <FeedbackError errors={errors} type="description" />
            </Form.Group>
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-3">
        <Card className="h-100 mb-3">
          <Card.Body>
            <label>Banner Image</label>
            <div {...getRootProps({ className: 'dropzone-area py-6' })}>
              <input
                {...getInputProps({ multiple: false })}
                accept="image/*"
                isInvalid={errors.bannerImage.length}
              />
              <Flex justifyContent="center">
                <img src={cloudUpload} alt="" width={25} className="me-2" />
                <p className="fs-0 mb-0 text-700">Drop your file here</p>
              </Flex>
            </div>
            {!!errors.bannerImage.length && (
              <div className="mt-3">
                <AlertError errors={errors} type="bannerImage" />
              </div>
            )}
            {seminarSection.bannerImageFullPath && (
              <div className="mt-3">
                <h6>File</h6>
                <Avatar
                  src={seminarSection.bannerImageFullPath}
                  rounded="0"
                  size="4xl"
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-4">
        <Card className="h-100 mb-3">
          <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
            <h5 className="mb-0">Media Items</h5>
            <Col className="d-flex justify-content-end">
              <Button
                className="btn btn-primary"
                size="sm"
                onClick={() => {
                  handlerShowModal();
                }}
              >
                Add
              </Button>
            </Col>
          </Card.Header>
          <Card.Body className="fs--1 fs-lg-0">
            <ReactSortable
              list={seminarMediaItems}
              setList={newlist => setSeminarMediaItems(newlist)}
              preventOnFilter={true}
              filter=".addImageButtonContainer"
              dragClass="sortableDrag"
            >
              <>
                {seminarMediaItems?.map((item, index) => (
                  <Card key={item?.title + index} className="mt-2">
                    <Card.Body>
                      <Card.Title as="h5">
                        {item.title}
                        <FontAwesomeIcon
                          className="ms-2"
                          onClick={() => editCard(index, item)}
                          icon="edit"
                        />
                        <FontAwesomeIcon
                          className="ms-2"
                          onClick={() => removeCard(index)}
                          icon="times"
                        />
                      </Card.Title>
                      <Card.Text>
                        <p>Type: {item.type}</p>
                        <p>
                          Membership Levels:{' '}
                          {formatAccess(item.access)
                            ? formatAccess(item.access)
                            : '-'}
                        </p>

                        <p>
                          Media:
                          {item?.files?.length > 0 ? (
                            <a href={item.files[0].fullPath}>
                              {item.files[0].fullPath}
                            </a>
                          ) : (
                            item.path && <a href={item.path}>{item.path}</a>
                          )}
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </>
            </ReactSortable>
            <AlertError errors={errors} type="items" />
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-3">
        <Card className="h-100 mb-3">
          <Card.Body>
            <Row>
              <Col>
                {isLoading ? (
                  <Button variant="success" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span>Loading...</span>
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    onClick={e => handlerSave(e)}
                    variant="success"
                  >
                    Save
                  </Button>
                )}
              </Col>
              <Col xs={7}></Col>
              <Col>
                <Link
                  to={`/dashboard/seminars/${seminarId}/edit`}
                  className="btn btn-secondary"
                >
                  Back to seminar update
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>

      <CreateMediaItem
        showModal={showCreateItemModal}
        handlerShowModal={handlerShowModalAddItem}
        updateList={handlerAddNewItemSeminarMediaItem}
        membershipLevels={membershipLevels}
      />

      <UpdateMediaItem
        showModal={showUpdateItemModal}
        handlerShowModal={handlerShowModalUpdateItem}
        membershipLevels={membershipLevels}
        itemSemninar={updateMediaItem}
        handlerUpdateItemSeminar={handlerUpdateItemSeminar}
        itemUpdateIndex={itemUpdateIndex}
      />
    </>
  );
};

export default SeminarSectionCreateForm;
