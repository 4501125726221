import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  Button,
  CloseButton,
  Row,
  Col,
  Spinner,
  Form,
  Alert
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { upload } from 'app/api/UploadsService';
import UserContext from 'app/context/UserContext';

const UpdateMediaItem = ({
  showModal,
  handlerShowModal,
  membershipLevels,
  itemSemninar,
  handlerUpdateItemSeminar,
  itemUpdateIndex
}) => {
  const { user } = useContext(UserContext);
  const [dataForm, setDataForm] = useState({
    title: '',
    files: [],
    access: []
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    title: '',
    path: ''
  });

  useEffect(() => {
    setDataForm({
      title: '',
      files: [],
      access: [],
      ...itemSemninar
    });

    hadlerClearErrors();
  }, [itemSemninar, showModal]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setDataForm({
        ...dataForm,
        files: acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      });
      hadlerClearErrors();
    }
  });

  const hadlerClearErrors = () => {
    setErrors({
      title: '',
      path: ''
    });
  };

  const handlerCloseModal = () => {
    setDataForm({
      title: '',
      files: [],
      access: []
    });

    handlerShowModal();
  };

  const handlerSelected = e => {
    if (e.target.checked) {
      setDataForm({
        ...dataForm,
        access: [...dataForm.access, +e.target.id]
      });
    } else {
      setDataForm({
        ...dataForm,
        access: dataForm.access.filter(itemAccess => itemAccess != e.target.id)
      });
    }
  };

  const handlerSave = e => {
    e.preventDefault();
    setLoading(true);
    setErrors({
      title: '',
      path: ''
    });

    let hasErrors = false;
    const error = {
      title: '',
      path: ''
    };

    if (!dataForm.title) {
      hasErrors = true;
      error.title = 'Title is required field';
    }

    if (dataForm.type == 'url' && !dataForm.path) {
      hasErrors = true;
      error.path = 'Url is required field';
    }

    if (dataForm.type == 'file' && !dataForm.files[0]) {
      hasErrors = true;
      error.path = 'File is required field';
    }

    if (hasErrors) {
      setErrors(error);
      setLoading(false);
      return;
    }

    if (dataForm.files[0]) {
      const formData = new FormData();
      formData.append('file', dataForm.files[0]);

      axios
        .request(upload(user.token, formData))
        .then(response => {
          handlerUpdateItemSeminar(itemUpdateIndex, {
            ...dataForm,
            path: response.data.relativePath,
            fullPath: response.data.path
          });
          setLoading(false);
          handlerCloseModal();
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      handlerUpdateItemSeminar(itemUpdateIndex, {
        ...dataForm
      });
      setLoading(false);
      handlerCloseModal();
    }
  };

  const removeFile = () => {
    setDataForm({
      ...dataForm,
      files: []
    });
  };

  const files = dataForm?.files?.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes{' '}
      <FontAwesomeIcon onClick={() => removeFile()} icon="times" />
    </li>
  ));

  const errorsAlert = type => {
    return (
      <Alert key={errors[type]} variant="danger">
        {errors[type]}
      </Alert>
    );
  };

  return (
    <Modal
      show={showModal}
      onHide={() => handlerCloseModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Content Item
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => handlerCloseModal()}
        />
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={dataForm.title}
            onChange={e => {
              setDataForm({ ...dataForm, title: e.target.value });
              hadlerClearErrors();
            }}
          />
        </Form.Group>

        {errors['title'] && errorsAlert('title')}

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Type</Form.Label>
          <Form.Check
            inline
            type="radio"
            id="item1Radio"
            label="File"
            name="inline-radio"
            checked={dataForm.type === 'file'}
            onChange={() => {
              setDataForm({ ...dataForm, type: 'file', files: [], path: '' });
              hadlerClearErrors();
            }}
            on
            className="ms-2"
          />
          <Form.Check
            inline
            type="radio"
            id="item2Radio"
            checked={dataForm.type === 'url'}
            label="Url"
            name="inline-radio"
            onChange={() => {
              setDataForm({ ...dataForm, type: 'url', files: [], path: '' });
              hadlerClearErrors();
            }}
          />
        </Form.Group>

        {dataForm.type == 'url' && (
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>URL</Form.Label>
            <Form.Control
              type="text"
              value={dataForm.path}
              onChange={e => {
                setDataForm({ ...dataForm, path: e.target.value });
                hadlerClearErrors();
              }}
            />
          </Form.Group>
        )}

        {dataForm.type == 'file' && (
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Upload File</Form.Label>
            <div {...getRootProps({ className: 'dropzone-area py-6' })}>
              <input {...getInputProps({ multiple: false })} />
              <Flex justifyContent="center">
                <img src={cloudUpload} alt="" width={25} className="me-2" />
                <p className="fs-0 mb-0 text-700">Drop your file here</p>
              </Flex>
            </div>
            <div className="mt-3">
              {dataForm.files.length > 0 && (
                <>
                  <h6>File</h6>
                  <ul>{files}</ul>
                </>
              )}

              {!dataForm.files.length && <p>{dataForm.path}</p>}
            </div>
          </Form.Group>
        )}

        {errors['path'] && errorsAlert('path')}

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Membership levels</Form.Label>
          {membershipLevels.map(membershipLevel => (
            <Form.Check
              type="checkbox"
              key={membershipLevel.id}
              id={membershipLevel.id}
              label={membershipLevel.title}
              onChange={e => {
                handlerSelected(e);
                hadlerClearErrors();
              }}
              value={dataForm?.access?.includes(membershipLevel.id)}
              checked={dataForm?.access?.includes(membershipLevel.id)}
            />
          ))}
        </Form.Group>

        <Row>
          <Col>
            {loading ? (
              <Button variant="success" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span>Loading...</span>
              </Button>
            ) : (
              <Button
                type="submit"
                onClick={e => handlerSave(e)}
                variant="success"
              >
                Save
              </Button>
            )}
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              onClick={() => handlerCloseModal()}
              variant="outline-primary"
            >
              Close
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

UpdateMediaItem.propTypes = {
  showModal: PropTypes.bool,
  handlerShowModal: PropTypes.func,
  membershipLevels: PropTypes.array,
  itemSemninar: PropTypes.object,
  handlerUpdateItemSeminar: PropTypes.func,
  itemUpdateIndex: PropTypes.number
};

export default UpdateMediaItem;
