import React from 'react';
import Helmet from 'react-helmet';
import { Card } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import LevelForm from './LevelForm';

export default function Level() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | Membership Level Editing</title>
      </Helmet>

      <PageHeader title="Level Editing" className="mb-3"></PageHeader>

      <Card className="mb-3">
        <Card.Body>
          <LevelForm />
        </Card.Body>
      </Card>
    </>
  );
}
