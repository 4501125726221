import { useState, useContext } from 'react';
import axios from 'axios';
import UserContext from 'app/context/UserContext';
import { useNavigate } from 'react-router-dom';

const useRequest = () => {
  const [response, setResponse] = useState();
  const [data, setData] = useState(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState();

  const { removeUser } = useContext(UserContext);
  const navigate = useNavigate();

  const fetchData = async params => {
    try {
      const result = await axios.request(params);
      setResponse(result);
      setData(result.data);
    } catch (err) {
      if (err?.response?.status === 401) {
        removeUser();
        navigate('/login');
      }

      setError(err);
    } finally {
      setLoading(false);
      setIsLoaded(true);
    }
  };

  const sendData = async params => {
    setLoading(true);
    setIsLoaded(false);

    await fetchData(params);
  };

  return {
    response: response,
    data: data,
    error: error,
    loading: loading,
    isLoading: loading,
    isLoaded: isLoaded,
    sendData: sendData,
    fetch: sendData,
    send: sendData
  };
};

export default useRequest;
