import React, { useMemo, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import useRequest from 'app/hooks/useRequest';
import { one, update as updateMember, remove } from 'app/api/MemberService';
import { useParams, useNavigate } from 'react-router-dom';
import UserContext from 'app/context/UserContext';

import ReferralsTable from './components/ReferralsTable';
import MemberHeader from './components/MemberHeader';
import Info from './components/Info';
import axios from 'axios';
import { toast } from 'react-toastify';
import TotalViews from './components/TotalViews';
import AheadOfChain from './components/AheadOfChain';
import { Col, Row } from 'react-bootstrap';

export default function Member() {
  const params = useParams();
  const { user } = useContext(UserContext);
  const { response, sendData } = useRequest();
  const navigate = useNavigate();

  const messages = {
    action: 'Status updated successfully'
  };

  useEffect(() => {
    sendResponse();
  }, [params.id]);

  const member = useMemo(() => {
    return response?.data ? response?.data : [];
  }, [response]);

  function sendResponse() {
    const config = one(user.token, params.id);
    sendData(config);
  }

  function handleAction(status) {
    axios
      .request(updateMember(user.token, member.id, { status: status }))
      .then(() => {
        toast.success(messages['action'], {
          theme: 'colored'
        });
        sendResponse();
      })
      .catch(() => {
        toast.error('Error', {
          theme: 'colored'
        });
      });
  }

  function handleDeactivate() {
    if (
      !confirm(
        'This will completely reset all user settings and balances without ability to restore. Are you sure to perform this operation?'
      )
    ) {
      return;
    }
    axios
      .request(remove(user.token, member.id))
      .then(() => {
        navigate('/dashboard/members');
      })
      .catch(() => {
        toast.error('Error', {
          theme: 'colored'
        });
      });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Member | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <MemberHeader
        member={member}
        refreshMember={sendResponse}
        handleAction={handleAction}
        handleDeactivate={handleDeactivate}
      />
      <Info member={member} />
      <ReferralsTable />

      <Row className="g-3 mb-3">
        <Col xl={6}>
          <TotalViews />
        </Col>
        <Col xl={6}>
          <AheadOfChain member={member} />
        </Col>
      </Row>
    </>
  );
}
