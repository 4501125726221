import { urlBaseApi } from '../consts';

const urlBase = urlBaseApi + '/admins';

export const login = (email, password) => {
  return {
    url: `${urlBase}/login`,
    method: 'POST',
    data: { email, password }
  };
};

export const check = token => {
  return {
    url: `${urlBase}/check`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};
