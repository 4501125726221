export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: []
};

export const infoRoutes = {
  label: 'Info',
  labelDisable: false,
  name: 'Dashboard',
  active: true,
  children: [
    {
      name: 'Members',
      icon: 'users',
      to: '/dashboard/members',
      exact: true,
      active: true
    },
    {
      name: 'Content',
      icon: 'folder',
      to: '/dashboard/contents',
      exact: true,
      active: true
    },
    {
      name: 'Withdrawals',
      icon: 'wallet',
      to: '/dashboard/withdrawals',
      exact: true,
      active: true
    },
    {
      name: 'Seminars',
      icon: 'laptop',
      to: '/dashboard/seminars',
      exact: true,
      active: true
    }
  ]
};

export const settingsRoutes = {
  label: 'Settings',
  labelDisable: false,
  name: 'Dashboard',
  active: true,
  icon: 'chart-pie',
  children: [
    {
      name: 'Dashboard',
      icon: 'th',
      to: '/dashboard/settings/dashboard',
      exact: true,
      active: true
    },
    {
      name: 'Landing',
      icon: 'landmark',
      to: '/dashboard/settings/landing',
      exact: true,
      active: true
    },
    {
      name: 'Membership',
      icon: 'users',
      active: true,
      children: [
        {
          name: 'Common',
          to: '/dashboard/settings/memberships-common',
          exact: true,
          active: true
        },
        {
          name: 'Levels',
          to: '/dashboard/settings/memberships-levels',
          exact: true,
          active: true
        },
        {
          name: 'Description',
          to: '/dashboard/settings/memberships-desc',
          exact: true,
          active: true
        }
      ]
    }
  ]
};

export default [infoRoutes, settingsRoutes];
