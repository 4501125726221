import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Card, Col, Dropdown, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import MemberEditForm from './MemberEditForm';

const MemberHeader = ({
  member,
  handleAction,
  handleDeactivate,
  refreshMember
}) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const [showEditForm, setShowEditForm] = useState(false);

  function activate() {
    handleAction('active');
  }

  function discard() {
    handleAction('inactive');
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col>
              <h4 className="my-1">{member.name}</h4>
            </Col>
            <Col xs="auto">
              <Dropdown className="d-inline-block ms-2">
                <Dropdown.Toggle
                  as={Button}
                  variant="falcon-default"
                  size="sm"
                  className="dropdown-caret-none"
                >
                  <FontAwesomeIcon icon="ellipsis-h" />
                </Dropdown.Toggle>
                <Dropdown.Menu align={isRTL ? 'end' : 'start'}>
                  {member.status === 'pending' ? (
                    <>
                      <Dropdown.Item
                        onClick={activate}
                        disabled={member.status === 'pending' ? false : true}
                        className={classNames(
                          member.status === 'pending' && 'text-success'
                        )}
                      >
                        Activate
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={discard}
                        disabled={member.status === 'pending' ? false : true}
                        className={classNames(
                          member.status === 'pending' && 'text-danger'
                        )}
                      >
                        Discard
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  ) : (
                    ''
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      setShowEditForm(true);
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={handleDeactivate}
                    className="text-danger"
                  >
                    Deactivate
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Card.Header>
      </Card>

      <Modal
        show={showEditForm}
        onHide={() => setShowEditForm(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {member.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MemberEditForm member={member} refreshMember={refreshMember} />
        </Modal.Body>
      </Modal>
    </>
  );
};

MemberHeader.propTypes = {
  member: PropTypes.any,
  handleAction: PropTypes.func,
  handleDeactivate: PropTypes.func
};

export default MemberHeader;
