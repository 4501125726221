import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import {
  list as fetchMembers,
  update as updateMember,
  remove as removeMember
} from 'app/api/MemberService';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import TableWrapper from './TableWrapper';
import TableFooter from './TableFooter';
import { Link } from 'react-router-dom';
import Date from 'app/components/common/Date';
import BadgeMemberStatus from 'app/components/common/BadgeMemberStatus';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function WithdrawalsTable() {
  const { user } = useContext(UserContext);

  // --- Fetch Members ------------------------------------------------------------

  const [isFetched, setIsFetched] = useState(false);
  const { response: membersResponse, sendData: requestMembers } = useRequest();

  useEffect(() => {
    requestMembers(fetchMembers(user.token));
  }, []);

  let tableData = useMemo(() => {
    let list = membersResponse?.data ? membersResponse.data : [];

    if (membersResponse?.data) {
      setIsFetched(true);
    }

    return list;
  }, [membersResponse]);

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      Cell: props => {
        const curRowNum = props.cell.row.id;
        const memberId = props.data[curRowNum].id;
        return (
          <Link to={`/dashboard/members/${memberId}`}>{props.cell.value}</Link>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Email'
    },
    {
      accessor: 'MemberSubscription.membershipLevel',
      Header: 'Membership'
    },
    {
      accessor: 'MemberSubscription.amount',
      Header: 'Amount'
    },
    {
      accessor: 'MemberSubscription.receiptNumber',
      Header: 'Receipt No.',
      Cell: props => {
        return props.cell.value ? props.cell.value : null;
      }
    },
    {
      accessor: 'MemberSubscription.startedAt',
      Header: 'Paid Date',
      Cell: props => {
        return props.cell.value ? <Date date={props.cell.value} /> : null;
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: props => {
        return <BadgeMemberStatus status={props.cell.value} />;
      }
    },
    {
      accessor: 'id'
    }
  ];

  // --- Actions ------------------------------------------------------------------

  const { response: actionResponse, sendData: requestAction } = useRequest();

  function action(id, type, status = '') {
    if (type === 'update') {
      requestAction(
        updateMember(user.token, id, {
          status: status
        })
      );
    }

    if (type === 'remove') {
      if (
        !confirm(
          'This will completely reset all user settings and balances without ability to restore. Are you sure to perform this operation?'
        )
      ) {
        return;
      }

      axios
        .request(removeMember(user.token, id))
        .then(() => {
          requestMembers(fetchMembers(user.token));
          toast.success('Member removed', {
            theme: 'colored'
          });
        })
        .catch(() => {
          toast.error('Error', {
            theme: 'colored'
          });
        });
    }
  }

  useMemo(() => {
    if (actionResponse?.data) {
      const rsp = actionResponse?.data;
      tableData = tableData.map(member => {
        if (member.id == rsp.id) {
          member.status = rsp.status;
          toast.success('Status updated successfully', {
            theme: 'colored'
          });
        }
        return member;
      });
    }
  }, [actionResponse]);

  return (
    <>
      {!isFetched ? (
        'Loading...'
      ) : (
        <TableWrapper
          columns={columns}
          data={tableData}
          action={action}
          sortable
          pagination
          perPage={10}
          dropdown
          dropdownColumnWidth={60}
        >
          <Row className="flex-end-center mb-3">
            <Col sm={6} lg={4}>
              <AdvanceTableSearchBox table />
            </Col>
          </Row>

          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />

          <div className="mt-3">
            <TableFooter
              rowCount={tableData.length}
              rowsPerPageOptions={[10, 20, 30]}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </TableWrapper>
      )}
    </>
  );
}

WithdrawalsTable.propTypes = {
  cell: PropTypes.any,
  data: PropTypes.any
};
