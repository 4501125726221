import { urlBaseApi } from '../consts';

const urlBase = urlBaseApi + '/members';

export const list = token => {
  return {
    url: `${urlBase}/`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const one = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const update = (token, id, data) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: { ...data }
  };
};

export const remove = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const subscriptions = (token, id) => {
  return {
    url: `${urlBaseApi}/subscriptions/${id}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getReferrals = (token, id) => {
  return {
    url: `${urlBaseApi}/members/${id}/referrals`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const getTotalViews = (token, id) => {
  return {
    url: `${urlBaseApi}/members/${id}/total-views`,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};
