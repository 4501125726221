import React from 'react';
import PropTypes from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';
import { getTypeBadgeByMemberStatus } from 'app/helpers/colors';

const BadgeMemberStatus = ({ status }) => {
  return (
    <SoftBadge bg={getTypeBadgeByMemberStatus(status)} className="me-2">
      {status}
    </SoftBadge>
  );
};

BadgeMemberStatus.propTypes = {
  status: PropTypes.string
};

export default BadgeMemberStatus;
