import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import { getWithdrawals, putWithdrawal } from 'app/api/WithdrawalService';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import WithdrawalsTableWrapper from './WithdrawalsTableWrapper';
import WithdrawalsTableFooter from './WithdrawalsTableFooter';
import SoftBadge from 'components/common/SoftBadge';
import { getTypeBadgeByMemberStatus } from 'app/helpers/colors';
import WalletAdress from 'app/components/common/WalletAdress';

export default function WithdrawalsTable() {
  const { user } = useContext(UserContext);
  const [isFetched, setIsFetched] = useState(false);

  const { response: listResponse, sendData: listRequest } = useRequest();

  const columns = [
    {
      accessor: 'id',
      Header: 'ID'
    },
    {
      accessor: 'Member.name',
      Header: 'Name',
      Cell: props => {
        return (
          <>
            <Link to={`/dashboard/members/${props?.row?.original?.Member?.id}`}>
              {props.cell.value}
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'Member.email',
      Header: 'Email'
    },
    {
      accessor: 'createdAt',
      Header: 'Request Date',
      Cell: props => {
        // TODO: It would be cool to replace the line with <Moment>...</Moment>.
        // Also, we need to create custom <Moment> in app/common to use it
        // across the whole projec.
        return moment(props.cell.value).format('DD-MM-YYYY HH:mm');
      }
    },
    {
      accessor: 'amount',
      Header: 'Amount'
    },
    {
      accessor: 'walletAddress',
      Header: 'Wallet Address',
      Cell: props => {
        return <WalletAdress wallet={props.cell.value} />;
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: props => {
        return renderBadge(props.cell.value);
      }
    }
  ];

  let tableData = useMemo(() => {
    let list = listResponse?.data ? listResponse.data : [];

    if (listResponse?.data) {
      setIsFetched(true);
    }

    return list;
  }, [listResponse]);

  useEffect(() => {
    listRequest(getWithdrawals(user.token));
  }, []);

  // TODO: replace getTypeBadgeByMemberStatus with custom component StatusBadge
  // <StatusBadge slug={slug} text={text}>
  function renderBadge(status) {
    return (
      <SoftBadge bg={getTypeBadgeByMemberStatus(status)} className="me-2">
        {status}
      </SoftBadge>
    );
  }

  // --- Actions ------------------------------------------------------------------

  const { response: actionResponse, sendData: actionRequest } = useRequest();

  function action(withdrawal) {
    actionRequest(putWithdrawal(user.token, withdrawal));
  }

  useMemo(() => {
    if (actionResponse?.data) {
      const rsp = actionResponse?.data;
      tableData = tableData.map(withdrawal => {
        if (withdrawal.id == rsp.id) {
          withdrawal.status = rsp.status;
          toast.success('Status updated successfully', {
            theme: 'colored'
          });
        }

        return withdrawal;
      });
    }
  }, [actionResponse]);

  return (
    <>
      {!isFetched ? (
        'Loading...'
      ) : (
        <WithdrawalsTableWrapper
          columns={columns}
          data={tableData}
          action={action}
          sortable
          pagination
          perPage={10}
          dropdown
          dropdownColumnWidth={60}
        >
          <Row className="flex-end-center mb-3">
            <Col sm={6} lg={4}>
              <AdvanceTableSearchBox table />
            </Col>
          </Row>

          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />

          <div className="mt-3">
            <WithdrawalsTableFooter
              rowCount={tableData.length}
              rowsPerPageOptions={[10, 20, 30]}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </WithdrawalsTableWrapper>
      )}
    </>
  );
}

WithdrawalsTable.propTypes = {
  cell: PropTypes.any,
  row: PropTypes.any
};
