import React, { useContext, useState, useMemo, useEffect } from 'react';
import { Col, Card, Form, Row, Button, Spinner } from 'react-bootstrap';
import PageHeader from 'app/components/header/PageHeader';
import UserContext from 'app/context/UserContext';
import { update } from 'app/api/SeminarSectionService';
import { bulkUpdate, create as createMedia } from 'app/api/SeminarMediaService';
import { listMediaItems as getListMediaItems } from 'app/api/SeminarSectionService';
import { getAll } from 'app/api/MembershipsService';
import useRequest from 'app/hooks/useRequest';
import { ReactSortable } from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { useDropzone } from 'react-dropzone';
import { upload } from 'app/api/UploadsService';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Avatar from 'components/common/Avatar';
import CreateMediaItem from './create-media-item/CreateMediaItem';
import UpdateMediaItem from './update-media-item/UpdateMediaItem';
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import FeedbackError from 'app/components/common/FeedbackError';
import AlertError from 'app/components/common/AlertError';

const SeminarSectionUpdateForm = ({ seminarOld, seminarMediaItemOld }) => {
  const { seminarId } = useParams();
  const [itemUpdateIndex, setItemUpdateIndex] = useState(0);
  const [showCreateItemModal, setShowCreateItemModal] = useState(false);
  const [showUpdateItemModal, setShowUpdateItemModal] = useState(false);
  const [updateMediaItem, setUpdateMediaItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [seminarSection, setSeminarSection] = useState({
    ...seminarOld
  });
  const [seminarMediaItem, setSeminarMediaItem] = useState([]);
  const [errors, setErrors] = useState({
    title: [],
    description: [],
    files: [],
    bannerImage: [],
    items: []
  });

  const { response, sendData } = useRequest();
  const { user } = useContext(UserContext);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      if (!acceptedFiles[0].type.includes('image')) {
        setErrors({
          ...errors,
          bannerImage: [{ msg: 'Not valid type file' }]
        });

        return;
      }

      setErrors({
        ...errors,
        bannerImage: []
      });

      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);

      axios.request(upload(user.token, formData)).then(response => {
        setSeminarSection({
          ...seminarSection,
          files: acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          ),
          bannerImage: response.data.relativePath,
          bannerImageFullPath: response.data.path
        });
      });
    }
  });

  useEffect(() => {
    setSeminarSection({
      ...seminarOld
    });

    sendResponse();
  }, [seminarOld]);

  useEffect(() => {
    setSeminarMediaItem([...seminarMediaItemOld]);
  }, [seminarMediaItemOld]);

  function sendResponse() {
    const config = getAll(user.token);
    sendData(config);
  }

  const membershipLevels = useMemo(() => {
    return response?.data ? response?.data : [];
  }, [response]);

  const formatAccess = access => {
    const accessTitles = [];
    membershipLevels?.forEach(element => {
      if (access.includes(element.id)) {
        accessTitles.push(element.title);
      }
    });

    return accessTitles.join(', ');
  };

  const removeCard = indexRemove => {
    setSeminarMediaItem(
      seminarMediaItem.filter((value, _index) => {
        return _index != indexRemove;
      })
    );
  };

  const handlerSave = e => {
    e.preventDefault();

    setIsLoading(true);

    axios
      .request(update(user.token, seminarSection.id, seminarSection))
      .then(() => {
        const formItems = seminarMediaItem.map((item, index) => {
          const newItem = { ...item };
          newItem.seminarSectionId = seminarSection.id;
          newItem.order = index;
          delete newItem.files;
          return newItem;
        });

        axios
          .request(bulkUpdate(user.token, formItems))
          .then(() => {
            toast.success('Seminar Section with media item created.', {
              theme: 'colored'
            });
          })
          .catch(error => {
            const errors = {
              title: [],
              description: [],
              files: [],
              bannerImage: [],
              items: []
            };

            error.response.data.errors.map(item => {
              errors['items'].push(item);
            });

            setErrors(errors);
          });
      })
      .catch(error => {
        const errors = {
          title: [],
          description: [],
          files: [],
          bannerImage: [],
          items: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editCard = (index, item) => {
    setUpdateMediaItem(item);
    setItemUpdateIndex(index);
    handlerShowModalUpdateItem();
  };

  const handlerShowModal = () => {
    setShowCreateItemModal(!showCreateItemModal);
  };

  const handlerAddNewItemSeminarMediaItem = item => {
    axios
      .request(update(user.token, seminarSection.id, seminarSection))
      .then(() => {
        axios
          .request(
            createMedia(user.token, {
              ...item,
              order: seminarMediaItem.length + 1,
              seminarSectionId: seminarSection.id
            })
          )
          .then(() => {
            axios
              .request(getListMediaItems(user.token, seminarSection.id))
              .then(response => {
                setSeminarMediaItem(response.data);
              });

            toast.success('Seminar Section with media item created.', {
              theme: 'colored'
            });
          });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlerShowModalAddItem = () => {
    handlerShowModal();
  };

  const handlerShowModalUpdateItem = () => {
    setShowUpdateItemModal(!showUpdateItemModal);
  };

  const handlerUpdateItemSeminar = (changeIndex, newItem) => {
    setSeminarMediaItem(
      seminarMediaItem.map((value, index) => {
        return index == changeIndex ? newItem : value;
      })
    );
  };

  return (
    <>
      <PageHeader
        col={{ xs: 12 }}
        title={
          'Update section ' +
          (seminarSection?.title ? seminarSection.title : '')
        }
        description=""
        className="mb-3"
      ></PageHeader>
      <Row className="g-1 mb-4">
        <Card className="h-100">
          <Card.Body className="fs--1 fs-lg-0">
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={seminarSection.title}
                onChange={e => {
                  setSeminarSection({
                    ...seminarSection,
                    title: e.target.value
                  });
                }}
                placeholder="New Section"
                isInvalid={errors.title.length}
              />
              <FeedbackError errors={errors} type="title" />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={seminarSection.description}
                onChange={e => {
                  setSeminarSection({
                    ...seminarSection,
                    description: e.target.value
                  });
                }}
                isInvalid={errors.description.length}
              />
              <FeedbackError errors={errors} type="description" />
            </Form.Group>
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-3">
        <Card className="h-100 mb-3">
          <Card.Body>
            <label>Banner Image</label>
            <div {...getRootProps({ className: 'dropzone-area py-6' })}>
              <input
                {...getInputProps({ multiple: false })}
                accept="image/*"
                isInvalid={errors.bannerImage.length}
              />
              <Flex justifyContent="center">
                <img src={cloudUpload} alt="" width={25} className="me-2" />
                <p className="fs-0 mb-0 text-700">Drop your file here</p>
              </Flex>
            </div>
            {!!errors.bannerImage.length && (
              <div className="mt-3">
                <AlertError errors={errors} type="bannerImage" />
              </div>
            )}
            {seminarSection.bannerImage && (
              <div className="mt-3">
                <h6>File</h6>
                <Avatar
                  src={seminarSection.bannerImageFullPath}
                  rounded="0"
                  size="4xl"
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-4">
        <Card className="h-100 mb-3">
          <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
            <h5 className="mb-0">Media Items</h5>
            <Col className="d-flex justify-content-end">
              <Button
                className="btn btn-primary"
                size="sm"
                onClick={() => {
                  handlerShowModal();
                }}
              >
                Add
              </Button>
            </Col>
          </Card.Header>
          <Card.Body className="fs--1 fs-lg-0">
            <ReactSortable
              list={seminarMediaItem}
              setList={newlist => setSeminarMediaItem(newlist)}
              preventOnFilter={true}
              filter=".addImageButtonContainer"
              dragClass="sortableDrag"
            >
              <>
                {seminarMediaItem?.map((item, index) => (
                  <Card key={item?.title + index} className="mt-2">
                    <Card.Body>
                      <Card.Title as="h5">
                        {item.title}
                        <FontAwesomeIcon
                          className="ms-2"
                          onClick={() => editCard(index, item)}
                          icon="edit"
                        />
                        <FontAwesomeIcon
                          className="ms-2"
                          onClick={() => removeCard(index)}
                          icon="times"
                        />
                      </Card.Title>
                      <Card.Text>
                        <p>Type: {item.type}</p>
                        <p>
                          Membership Levels:{' '}
                          {formatAccess(item.access)
                            ? formatAccess(item.access)
                            : '-'}
                        </p>
                        <p>
                          Media:{' '}
                          {item?.files?.length > 0 ? (
                            <a href={item.fullPath}>{item.fullPath}</a>
                          ) : (
                            item.path && <a href={item.path}>{item.path}</a>
                          )}
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </>
            </ReactSortable>
            <AlertError errors={errors} type="items" />
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-3">
        <Card className="h-100 mb-3">
          <Card.Body>
            <Row>
              <Col>
                {isLoading ? (
                  <Button variant="success" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span>Loading...</span>
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    onClick={e => handlerSave(e)}
                    variant="success"
                  >
                    Save
                  </Button>
                )}
              </Col>
              <Col xs={7}></Col>
              <Col>
                <Link
                  to={`/dashboard/seminars/${seminarId}/edit`}
                  className="btn btn-secondary"
                >
                  Back to seminar update
                </Link>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>

      <CreateMediaItem
        showModal={showCreateItemModal}
        handlerShowModal={handlerShowModalAddItem}
        updateList={handlerAddNewItemSeminarMediaItem}
        membershipLevels={membershipLevels}
      />

      <UpdateMediaItem
        showModal={showUpdateItemModal}
        handlerShowModal={handlerShowModalUpdateItem}
        membershipLevels={membershipLevels}
        itemSemninar={updateMediaItem}
        handlerUpdateItemSeminar={handlerUpdateItemSeminar}
        itemUpdateIndex={itemUpdateIndex}
      />
    </>
  );
};

SeminarSectionUpdateForm.propTypes = {
  seminarOld: PropTypes.any,
  seminarMediaItemOld: PropTypes.array
};

export default SeminarSectionUpdateForm;
