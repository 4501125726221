import { useState } from 'react';

const useUser = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  function addUser(user) {
    if (localStorage.getItem('user')) {
      removeUser();
    }

    localStorage.setItem('user', JSON.stringify(user));
    setUser(user);
  }

  function removeUser() {
    localStorage.removeItem('user');
    setUser(false);
  }

  return {
    user,
    addUser,
    removeUser
  };
};

export default useUser;
