import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AppContext from 'context/Context';

const TableDropdown = ({
  btnRevealClass,
  drop,
  children,
  icon = 'ellipsis-h',
  row,
  action
}) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  function onProcess() {
    // TODO: repcae status: 'processed' with special calss helper
    action(row.values.id, 'update', 'active');
  }

  function onReject() {
    // TODO: repcae status: 'processed' with special calss helper
    action(row.values.id, 'update', 'inactive');
  }

  function onRemove() {
    // TODO: repcae status: 'processed' with special calss helper
    action(row.values.id, 'remove');
  }

  return (
    <Dropdown
      className="font-sans-serif btn-reveal-trigger"
      align={isRTL ? 'start' : 'end'}
      drop={drop}
      autoClose="outside"
    >
      <Dropdown.Toggle
        variant="link"
        size="sm"
        data-boundary="viewport"
        className={classNames('text-600', {
          [btnRevealClass]: btnRevealClass,
          'btn-reveal': !btnRevealClass
        })}
      >
        <FontAwesomeIcon icon={icon} className="fs--2" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="border py-0">
        {children}
        {!children && (
          <div className="py-2">
            {row.values.status === 'pending' ? (
              <>
                <Dropdown.Item
                  onClick={onProcess}
                  className="text-success"
                  disabled={row.values.status === 'pending' ? false : true}
                >
                  <span
                    className={classNames({
                      'link-300': row.values.status !== 'pending'
                    })}
                  >
                    Activate
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={onReject}
                  className="text-danger"
                  disabled={row.values.status === 'pending' ? false : true}
                >
                  <span
                    className={classNames({
                      'link-300': row.values.status !== 'pending'
                    })}
                  >
                    Discard
                  </span>
                </Dropdown.Item>
                <Dropdown.Divider />
              </>
            ) : (
              ''
            )}
            <Dropdown.Item onClick={onRemove} className="text-danger">
              <span>Deactivate</span>
            </Dropdown.Item>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

TableDropdown.propTypes = {
  btnRevealClass: PropTypes.string,
  drop: PropTypes.string,
  row: PropTypes.any,
  action: PropTypes.any,
  children: PropTypes.node,
  icon: PropTypes.string
};

export default TableDropdown;
