import { urlBaseApi as apiUrl } from '../consts';

const endpointUrl = apiUrl + '/options';

export const getOption = (user, optionName) => {
  return {
    url: `${endpointUrl}/${optionName}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + user.token
    }
  };
};

export const updateOption = (user, optionName, optionValue) => {
  return {
    url: `${endpointUrl}`,
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + user.token
    },
    data: {
      optionName: optionName,
      optionValue: optionValue
    }
  };
};
