import React from 'react';
import Helmet from 'react-helmet';
import ContentTable from './ContentTable';

export default function Contents() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Contents | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <ContentTable />
    </>
  );
}
