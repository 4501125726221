import React, { useContext, useEffect, useMemo } from 'react';
import UserContext from 'app/context/UserContext';
import { Modal, CloseButton, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { one } from 'app/api/ContentService';
import useRequest from 'app/hooks/useRequest';
import ReactPlayer from 'react-player';

const ModalUpload = ({ showModal, handlerShowModal, id }) => {
  const { user } = useContext(UserContext);
  const { response, sendData } = useRequest();

  useEffect(() => {
    sendResponse();
  }, [id]);

  function sendResponse() {
    if (id) {
      const config = one(user.token, id);
      sendData(config);
    }
  }

  const content = useMemo(() => {
    return response?.data ? response?.data : {};
  }, [response]);

  return (
    <Modal show={showModal} fullscreen={true} onHide={() => handlerShowModal()}>
      <Modal.Header>
        <Modal.Title>Preview</Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => handlerShowModal()}
        />
      </Modal.Header>
      <Modal.Body>
        {content?.type === 'image' ? (
          <Image src={content?.filename} fluid />
        ) : (
          <ReactPlayer
            url={content.filename}
            controls={true}
            playing={false}
            muted={false}
            width={'100%'}
            height={'100%'}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

ModalUpload.propTypes = {
  showModal: PropTypes.bool,
  handlerShowModal: PropTypes.func,
  id: PropTypes.number
};

export default ModalUpload;
