import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import { one } from 'app/api/MemberService';

export default function AheadOfChain({ member }) {
  if (typeof member !== 'object') {
    return null;
  }

  const { user } = useContext(UserContext);
  const { data: referralData, fetch: fetchReferral } = useRequest();
  const [referral, setReferral] = useState(null);

  useEffect(() => {
    if (member.referralId) {
      fetchReferral(one(user.token, member.referralId));
    } else {
      setReferral(null);
    }
  }, [member]);

  useEffect(() => {
    setReferral(referralData);
  }, [referralData]);

  return (
    <>
      {referral && (
        <Card className="h-100">
          <Card.Header>
            <h5>Ahead of Chain</h5>
          </Card.Header>
          <Card.Body className="border-top">
            {
              <Row>
                <Col md={12} lg={3} className="mb-3">
                  <div className="fs--1 text-400">Name</div>
                  <div>
                    <Link to={`/dashboard/members/${referral?.id}`}>
                      {referral?.name}
                    </Link>
                  </div>
                </Col>
                <Col md={12} lg={5} className="mb-3">
                  <div className="fs--1 text-400">Email</div>
                  <Link to={`/dashboard/members/${referral?.id}`}>
                    {referral?.email}
                  </Link>
                </Col>
                <Col md={12} lg={2} className="mb-3">
                  <div className="fs--1 text-400">Level</div>
                  <div>{referral.MemberSubscription?.membershipLevel}</div>
                </Col>
                <Col md={12} lg={2}>
                  <div className="fs--1 text-400">Amount</div>
                  <div>{referral?.balance}</div>
                </Col>
              </Row>
            }
          </Card.Body>
        </Card>
      )}
    </>
  );
}

AheadOfChain.propTypes = {
  member: PropTypes.any
};
