import React from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/common/PageHeader';
import Table from './components/Table';
import { Card } from 'react-bootstrap';

export default function Members() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Members | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <PageHeader title="Members" className="mb-3"></PageHeader>

      <Card className="mb-3">
        <Card.Body>
          <Table />
        </Card.Body>
      </Card>
    </>
  );
}
