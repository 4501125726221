export const getClassColorByMemberStatus = status => {
  let className = 'text-secondary';
  switch (status) {
    case 'active':
      className = 'text-success';
      break;
    case 'pending':
      className = 'text-warning';
      break;
  }

  return className;
};

export const getTypeBadgeByMemberStatus = status => {
  let className = 'secondary';
  switch (status) {
    case 'active':
    case 'processed':
      className = 'success';
      break;
    case 'pending':
      className = 'warning';
      break;
    case 'rejected':
      className = 'danger';
      break;
  }

  return className;
};
