import React, { useContext, useState, useEffect } from 'react';
import { Col, Card, Form, Row, Button } from 'react-bootstrap';
import PageHeader from 'app/components/header/PageHeader';
import UserContext from 'app/context/UserContext';
import { update, updateOrderAllItem } from 'app/api/SeminarsService';
import { ReactSortable } from 'react-sortablejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { remove } from 'app/api/SeminarSectionService';
import FeedbackError from 'app/components/common/FeedbackError';
import { toast } from 'react-toastify';

const selectOptions = [
  {
    id: 0,
    value: 'active'
  },
  {
    id: 1,
    value: 'archived'
  }
];

const SeminarUpdateForm = ({
  seminarOld,
  seminarSelectionItemOld,
  sendSeminarSlectionItemOld
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [seminar, setSeminar] = useState({
    ...seminarOld,
    status: seminarOld?.deletedAt ? 'archived' : 'active'
  });
  const [seminarSelectionItems, setSeminarSelectionItems] = useState([]);
  const { user } = useContext(UserContext);
  const [errors, setErrors] = useState({
    title: [],
    titleBlock: [],
    descriptionBlock: [],
    items: []
  });

  useEffect(() => {
    setSeminar({
      ...seminarOld,
      status: seminarOld?.deletedAt ? 'archived' : 'active'
    });
  }, [seminarOld]);

  useEffect(() => {
    setSeminarSelectionItems([...seminarSelectionItemOld]);
  }, [seminarSelectionItemOld]);

  const handlerSelected = e => {
    setSeminar({ ...seminar, status: e.target.value });
  };

  const removeCard = id => {
    let check = confirm(
      'When removed, it will no longer be possible to trust. Are you still sure what to delete?'
    );

    if (check) {
      axios
        .request(remove(user.token, id))
        .then(() => {
          sendSeminarSlectionItemOld();
          toast.success('Seminar section removed.', {
            theme: 'colored'
          });
        })
        .catch();
    }
  };

  const handlerSave = e => {
    e.preventDefault();

    setIsLoading(true);

    axios
      .request(update(user.token, seminar.id, seminar))
      .then(() => {
        toast.success('Seminar updated.', {
          theme: 'colored'
        });

        const formItems = seminarSelectionItems.map((item, index) => {
          item.order = index;
          return item;
        });

        axios
          .request(updateOrderAllItem(user.token, seminar.id, formItems))
          .then(() => {
            toast.success('Seminar sections updated.', {
              theme: 'colored'
            });
          })
          .catch(error => {
            const errors = {
              title: [],
              titleBlock: [],
              descriptionBlock: []
            };

            error.response.data.errors.map(item => {
              errors['items'].push(item);
            });

            setErrors(errors);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(error => {
        const errors = {
          title: [],
          titleBlock: [],
          descriptionBlock: []
        };

        error.response.data.errors.map(item => {
          errors[item.param].push(item);
        });

        setErrors(errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageHeader
        col={{ xs: 12 }}
        title={'Update seminar ' + (seminar?.title ? seminar.title : '')}
        description=""
        className="mb-3"
      ></PageHeader>
      <Row className="g-1 mb-4">
        <Card className="h-100">
          <Card.Body className="fs--1 fs-lg-0">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Status</Form.Label>
              <Form.Select onChange={e => handlerSelected(e)}>
                {selectOptions.map(selectOption => (
                  <>
                    <option
                      value={selectOption.value}
                      key={selectOption.id}
                      selected={selectOption.value === seminar.status}
                    >
                      {selectOption.value}
                    </option>
                  </>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={seminar?.title}
                onChange={e => {
                  setSeminar({
                    ...seminar,
                    title: e.target.value
                  });
                }}
                placeholder="New seminar"
                isInvalid={errors.title.length}
              />
              <FeedbackError errors={errors} type="title" />
            </Form.Group>
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-3">
        <Card className="h-100 mb-3">
          <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
            <h5 className="mb-0">Text block</h5>
          </Card.Header>
          <Card.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title block</Form.Label>
              <Form.Control
                type="text"
                value={seminar.titleBlock}
                onChange={e => {
                  setSeminar({
                    ...seminar,
                    titleBlock: e.target.value
                  });
                }}
                isInvalid={errors.titleBlock.length}
              />
              <FeedbackError errors={errors} type="titleBlock" />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={seminar.descriptionBlock}
                onChange={e => {
                  setSeminar({
                    ...seminar,
                    descriptionBlock: e.target.value
                  });
                }}
                isInvalid={errors.descriptionBlock.length}
              />
              <FeedbackError errors={errors} type="descriptionBlock" />
            </Form.Group>
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-4">
        <Card className="h-100 mb-3">
          <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
            <h5 className="mb-0">Seminar Sections</h5>
            <Col className="d-flex justify-content-end">
              <Link
                to={`/dashboard/seminars/${seminarOld.id}/section/create`}
                className="btn btn-primary"
                size="sm"
              >
                Add
              </Link>
            </Col>
          </Card.Header>
          <Card.Body className="fs--1 fs-lg-0">
            <ReactSortable
              list={seminarSelectionItems}
              setList={newlist => setSeminarSelectionItems(newlist)}
              preventOnFilter={true}
              filter=".addImageButtonContainer"
              dragClass="sortableDrag"
            >
              <>
                {seminarSelectionItems?.map((item, index) => (
                  <Card key={item?.title + index} className="mt-2">
                    <Card.Body>
                      <Card.Title as="h5">
                        {item.title}
                        <FontAwesomeIcon
                          className="ms-2"
                          onClick={() => {
                            navigate(
                              `/dashboard/seminars/${seminarOld.id}/section/${item?.id}/edit`
                            );
                          }}
                          icon="edit"
                        />
                        <FontAwesomeIcon
                          className="ms-2"
                          onClick={() => removeCard(item.id)}
                          icon="times"
                        />
                      </Card.Title>
                      <Card.Text>Description: {item.description}</Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </>
            </ReactSortable>
          </Card.Body>
        </Card>
      </Row>

      <Row className="g-1 mb-3">
        <Card className="h-100 mb-3">
          <Card.Body>
            <Row>
              <Col>
                <Button
                  type="submit"
                  onClick={e => handlerSave(e)}
                  variant="success"
                  disabled={isLoading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

SeminarUpdateForm.propTypes = {
  seminarOld: PropTypes.any,
  seminarSelectionItemOld: PropTypes.array,
  sendSeminarSlectionItemOld: PropTypes.func
};

export default SeminarUpdateForm;
