import React from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/common/PageHeader';
import List from './List';

export default function Levels() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | Membership Levels</title>
      </Helmet>
      <PageHeader title="Membership Levels" className="mb-3"></PageHeader>
      <List />
    </>
  );
}
