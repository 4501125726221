import React, { useContext, useEffect, useMemo } from 'react';
import { Col, Card, CardGroup, Row, Button } from 'react-bootstrap';
import PageHeader from 'app/components/header/PageHeader';
import useRequest from 'app/hooks/useRequest';
import UserContext from 'app/context/UserContext';
import { list, remove, update } from 'app/api/SeminarsService';
import { Link } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';
import axios from 'axios';
import { toast } from 'react-toastify';
import Date from 'app/components/common/Date';

const SeminarsCards = () => {
  const { response, sendData } = useRequest();
  const { user } = useContext(UserContext);

  useEffect(() => {
    sendResponse();
  }, []);

  function sendResponse() {
    const config = list(user.token);
    sendData(config);
  }

  const seminars = useMemo(() => {
    return response?.data ? response?.data : [];
  }, [response]);

  const handlerRemoveItem = id => {
    axios
      .request(remove(user.token, id))
      .then(() => {
        toast.success('Seminar arhived.', {
          theme: 'colored'
        });
        sendResponse();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handlerActivatedItem = id => {
    const seminar = seminars.find(item => item.id === id);

    axios
      .request(update(user.token, id, { ...seminar, status: 'activate' }))
      .then(() => {
        toast.success('Seminar activated.', {
          theme: 'colored'
        });
        sendResponse();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <>
      <PageHeader
        col={{ xs: 6 }}
        title="Seminars"
        description=""
        className="mb-3"
      >
        <Col className="d-flex justify-content-end">
          <Link
            to="/dashboard/seminars/create"
            className="btn btn-primary"
            size="sm"
          >
            Add
          </Link>
        </Col>
      </PageHeader>
      {seminars.length ? (
        <Row className="g-0">
          <CardGroup>
            {seminars.map(siminar => (
              <Col className="mb-4 mb-lg-1 p-2" sm={4} lg={4} key={siminar.id}>
                <Card>
                  <Card.Body>
                    <Card.Title as="h5">
                      <Link to={`/dashboard/seminars/${siminar.id}/edit`}>
                        {siminar.title}
                      </Link>
                      {!siminar.deletedAt ? (
                        <SoftBadge pill bg="success" className="ms-2 me-2">
                          active
                        </SoftBadge>
                      ) : (
                        <SoftBadge pill bg="secondary" className="ms-2 me-2">
                          archived
                        </SoftBadge>
                      )}
                    </Card.Title>
                    <Card.Text>
                      Section Qty: {siminar.SeminarSections.length}
                    </Card.Text>
                    <Card.Text>
                      Created date: <Date date={siminar.createdAt} />
                    </Card.Text>
                    <Card.Text>
                      <Link to={`/dashboard/seminars/${siminar.id}/edit`}>
                        <Button variant="primary" className="me-2 mb-1">
                          Edit
                        </Button>
                      </Link>
                      {!siminar.deletedAt ? (
                        <Button
                          variant="danger"
                          onClick={() => {
                            handlerRemoveItem(siminar.id);
                          }}
                          className="me-2 mb-1"
                        >
                          Archive
                        </Button>
                      ) : (
                        <Button
                          variant="success"
                          onClick={() => {
                            handlerActivatedItem(siminar.id);
                          }}
                          className="me-2 mb-1"
                        >
                          Activate
                        </Button>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </CardGroup>
        </Row>
      ) : (
        <Card>
          <Card.Body className="fs--1 fs-lg-0">No records found.</Card.Body>
        </Card>
      )}
    </>
  );
};
export default SeminarsCards;
