import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import useRequest from 'app/hooks/useRequest';
import UserContext from 'app/context/UserContext';
import { toast } from 'react-toastify';
import { upload } from 'app/api/UploadsService';
import defaultQr from 'app/assets/img/qr/qr-code.png';
import { getOption, updateOption } from 'app/api/OptionsService';

export default function QrForm() {
  const imageInputRef = React.useRef();
  const [qrFile, setQrFile] = useState();
  const [common, setCommon] = useState();

  const { user } = useContext(UserContext);

  const {
    response: fileResponse,
    send: fileSend,
    loading: fileLoading
  } = useRequest();

  const { data: commonData, fetch: fetchCommon } = useRequest();
  const { response: optRes, send: optReq } = useRequest();

  const handleSubmit = e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', qrFile);

    fileSend(upload(user.token, formData));
    imageInputRef.current.value = '';
  };

  useEffect(() => {
    if (!fileResponse) return null;

    if (fileResponse.status === 201) {
      setCommon({ ...common, qr: fileResponse.data.path });

      optReq(
        updateOption(
          user,
          'membership_common',
          JSON.stringify({ ...common, qr: fileResponse.data.path })
        )
      );
    }
  }, [fileResponse]);

  useEffect(() => {
    if (!optRes) return null;

    toast.success('New QR saved successfully', {
      theme: 'colored'
    });
  }, [optRes]);

  useEffect(() => {
    fetchCommon(getOption(user, 'membership_common'));
  }, []);

  useEffect(() => {
    if (!commonData) return null;
    setCommon(JSON.parse(commonData.optionValue));
  }, [commonData]);

  return (
    <Row>
      <Col lg={8}>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label>File</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              name="common[qr]"
              ref={imageInputRef}
              onChange={e => {
                setQrFile(e.target.files[0]);
              }}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            name="common[submit]"
            disabled={!qrFile || fileLoading}
          >
            Save
          </Button>
          {fileLoading && (
            <Spinner
              animation="grow"
              variant="primary"
              size="sm"
              className="ms-2"
            />
          )}
        </Form>
      </Col>
      <Col lg={4} className="text-center mt-5">
        <img src={common?.qr || defaultQr} width="120" />
      </Col>
    </Row>
  );
}
