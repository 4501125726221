import React, { useContext, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import { getAll } from 'app/api/MembershipsService';
import ListItem from './ListItem';

export default function List() {
  const { user } = useContext(UserContext);
  const { data: levels, fetch } = useRequest();

  useEffect(() => {
    fetch(getAll(user.token));
  }, []);

  return (
    <Row className="g-3 mb-3">
      {levels &&
        levels.map(level => <ListItem key={level.slug} level={level} />)}
    </Row>
  );
}
