import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useRequest from 'app/hooks/useRequest';
import UserContext from 'app/context/UserContext';
import { getOne, putOne } from 'app/api/MembershipsService';

export default function LevelForm() {
  const params = useParams();
  const { user } = useContext(UserContext);
  const { data, fetch, isLoaded } = useRequest();
  const {
    response: updateRes,
    send: updateSend,
    updateIsLoading
  } = useRequest();

  const [level, setLevel] = useState();

  useEffect(() => {
    fetch(getOne(user.token, params.id));
  }, []);

  useEffect(() => {
    if (!data) return null;
    setLevel({ ...data, featuresTextarea: featuresToText(data.featuresList) });
  }, [data]);

  useEffect(() => {
    if (!updateRes) return null;

    updateRes.status === 200 &&
      toast.success('Saved successfully', {
        theme: 'colored'
      });
  }, [updateRes]);

  const featuresToText = arr => {
    return arr.reduce((acc, item) => {
      acc += `${item.title}\n`;
      return acc;
    }, '');
  };

  const textToFeatures = text => {
    return text
      .split(/\r?\n/)
      .map((item, idx) => {
        return { id: idx, title: item };
      })
      .filter(item => {
        return item.title;
      });
  };

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    setLevel({ ...level, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    updateSend(
      putOne(user.token, {
        ...level,
        featuresList: textToFeatures(level.featuresTextarea)
      })
    );
  };

  const form = (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Title</Form.Label>
        <Form.Control
          required
          type="text"
          name="title"
          value={level && level.title}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Price</Form.Label>
        <Form.Control
          required
          type="number"
          name="price"
          value={level && level.price}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Content views amount for 1 token</Form.Label>
        <Form.Control
          required
          type="number"
          name="contentView"
          value={level && level.contentView}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Weekly token limit</Form.Label>
        <Form.Control
          required
          type="number"
          name="weeklyTokenLimit"
          value={level && level.weeklyTokenLimit}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Tagline</Form.Label>
        <Form.Control
          required
          type="text"
          name="subTitle"
          value={level && level.subTitle}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Features Title</Form.Label>
        <Form.Control
          required
          type="text"
          name="featuresTitle"
          value={level && level.featuresTitle}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Features</Form.Label>
        <Form.Control
          required
          as="textarea"
          name="featuresTextarea"
          value={level && level.featuresTextarea}
          rows={10}
          placeholder="Features Title"
          onChange={handleChange}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        name="common[submit]"
        disabled={updateIsLoading}
      >
        Save
      </Button>
      {updateIsLoading && (
        <Spinner
          animation="grow"
          variant="primary"
          size="sm"
          className="ms-2"
        />
      )}
    </Form>
  );

  return <>{isLoaded && form}</>;
}
