export const urlBaseApi = process.env.REACT_APP_BASE_URL_API;

export const textForTermsAndConditions = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Pellentesque adipiscing commodo elit at. Diam donec adipiscing tristique risus. Mauris augue neque gravida in fermentum et sollicitudin ac. Gravida cum sociis natoque penatibus. Vitae et leo duis ut diam quam nulla. Venenatis a condimentum vitae sapien pellentesque habitant morbi. Nisi scelerisque eu ultrices vitae. Vehicula ipsum a arcu cursus vitae. Tortor consequat id porta nibh. Eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum. Ut ornare lectus sit amet est placerat in egestas erat. Enim eu turpis egestas pretium aenean pharetra magna ac. Ut aliquam purus sit amet luctus venenatis lectus magna. Morbi blandit cursus risus at ultrices mi tempus.
Arcu ac tortor dignissim convallis aenean. Vitae ultricies leo integer malesuada nunc vel risus commodo viverra. Eget lorem dolor sed viverra ipsum nunc aliquet bibendum. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Donec massa sapien faucibus et molestie ac. Proin fermentum leo vel orci porta non pulvinar neque. Morbi quis commodo odio aenean sed. Suscipit adipiscing bibendum est ultricies integer quis auctor elit. Semper risus in hendrerit gravida rutrum quisque non tellus. Sed egestas egestas fringilla phasellus faucibus.
Nisl pretium fusce id velit. Cursus euismod quis viverra nibh. Venenatis urna cursus eget nunc scelerisque. Metus vulputate eu scelerisque felis imperdiet proin. Neque aliquam vestibulum morbi blandit cursus. Tellus at urna condimentum mattis pellentesque. Ut placerat orci nulla pellentesque dignissim enim sit amet venenatis. Amet est placerat in egestas. Cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo. Ut venenatis tellus in metus vulputate. Eu tincidunt tortor aliquam nulla facilisi cras. A scelerisque purus semper eget duis. Fermentum leo vel orci porta non pulvinar neque laoreet suspendisse. Vulputate enim nulla aliquet porttitor lacus. Vitae aliquet nec ullamcorper sit amet risus. Mauris pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus. Molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit.
Amet consectetur adipiscing elit pellentesque habitant morbi tristique. Amet nisl purus in mollis nunc sed id semper. Fermentum odio eu feugiat pretium nibh. Adipiscing commodo elit at imperdiet dui accumsan. A diam sollicitudin tempor id. Malesuada bibendum arcu vitae elementum. Consequat interdum varius sit amet. Amet mattis vulputate enim nulla aliquet porttitor lacus luctus. Hendrerit gravida rutrum quisque non tellus orci. Et ultrices neque ornare aenean euismod. Praesent tristique magna sit amet purus gravida quis. Viverra aliquet eget sit amet tellus cras adipiscing enim. Mauris a diam maecenas sed enim.
Arcu odio ut sem nulla pharetra diam sit amet nisl. Vitae et leo duis ut diam. Lectus nulla at volutpat diam ut venenatis tellus in. Pharetra sit amet aliquam id diam maecenas ultricies mi. Mauris ultrices eros in cursus turpis massa tincidunt. Id semper risus in hendrerit gravida. Libero justo laoreet sit amet. Purus non enim praesent elementum facilisis leo. Vel fringilla est ullamcorper eget nulla facilisi etiam. Ac odio tempor orci dapibus ultrices in. Maecenas pharetra convallis posuere morbi leo urna molestie. Id leo in vitae turpis.
Integer feugiat scelerisque varius morbi enim. Tortor posuere ac ut consequat semper viverra. Aenean et tortor at risus viverra adipiscing at. Volutpat sed cras ornare arcu dui vivamus. Eget gravida cum sociis natoque. Eget nulla facilisi etiam dignissim diam quis enim. Facilisis leo vel fringilla est ullamcorper eget nulla. Massa id neque aliquam vestibulum morbi. Enim sit amet venenatis urna cursus eget. Cursus risus at ultrices mi tempus. Sit amet dictum sit amet justo donec enim diam. Semper eget duis at tellus at. Mi sit amet mauris commodo quis.
Diam vulputate ut pharetra sit. Malesuada pellentesque elit eget gravida cum sociis natoque. Aenean pharetra magna ac placerat vestibulum. Quam lacus suspendisse faucibus interdum posuere lorem ipsum. Purus in mollis nunc sed id. Tortor condimentum lacinia quis vel eros donec. Sollicitudin tempor id eu nisl nunc mi ipsum. Quam id leo in vitae turpis massa sed elementum. Pharetra et ultrices neque ornare. Sed sed risus pretium quam vulputate dignissim. Sagittis id consectetur purus ut faucibus pulvinar. Enim diam vulputate ut pharetra sit. Dis parturient montes nascetur ridiculus mus. Phasellus faucibus scelerisque eleifend donec pretium.
Netus et malesuada fames ac. Amet tellus cras adipiscing enim. Quis viverra nibh cras pulvinar mattis nunc sed. Volutpat est velit egestas dui id ornare arcu odio. Viverra mauris in aliquam sem fringilla ut morbi. Nibh mauris cursus mattis molestie a iaculis at erat. Viverra orci sagittis eu volutpat. Feugiat scelerisque varius morbi enim. Odio ut enim blandit volutpat maecenas volutpat blandit. Viverra accumsan in nisl nisi. Massa sed elementum tempus egestas sed sed risus. Etiam non quam lacus suspendisse faucibus.
Pulvinar pellentesque habitant morbi tristique senectus et netus et. Arcu bibendum at varius vel pharetra vel turpis nunc eget. Et malesuada fames ac turpis egestas sed tempus. Enim ut tellus elementum sagittis vitae et leo duis ut. Purus viverra accumsan in nisl. Elementum nibh tellus molestie nunc non blandit massa enim. Blandit massa enim nec dui nunc. Fermentum iaculis eu non diam phasellus. Placerat duis ultricies lacus sed. Risus nec feugiat in fermentum posuere urna nec. Porttitor rhoncus dolor purus non enim praesent elementum facilisis. Senectus et netus et malesuada fames. Non blandit massa enim nec. Cursus euismod quis viverra nibh cras pulvinar mattis nunc. Amet volutpat consequat mauris nunc congue nisi vitae.
Molestie nunc non blandit massa enim nec dui. Ut tellus elementum sagittis vitae et leo. Est lorem ipsum dolor sit amet. Etiam tempor orci eu lobortis elementum nibh tellus molestie nunc. Diam sit amet nisl suscipit adipiscing bibendum est ultricies. Cursus risus at ultrices mi tempus. Quisque id diam vel quam elementum pulvinar. Integer feugiat scelerisque varius morbi enim nunc faucibus a. Quis auctor elit sed vulputate mi. Lorem mollis aliquam ut porttitor leo. Vestibulum morbi blandit cursus risus at ultrices mi tempus. Pulvinar neque laoreet suspendisse interdum consectetur libero. Facilisis mauris sit amet massa vitae tortor condimentum lacinia quis. Gravida quis blandit turpis cursus in hac habitasse platea. Etiam dignissim diam quis enim lobortis scelerisque fermentum. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi.
Feugiat nibh sed pulvinar proin. Purus sit amet volutpat consequat. Sed turpis tincidunt id aliquet risus feugiat in. Et ultrices neque ornare aenean euismod elementum. Urna molestie at elementum eu facilisis. Malesuada pellentesque elit eget gravida cum. Pretium nibh ipsum consequat nisl. Tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada proin. Commodo elit at imperdiet dui accumsan. A arcu cursus vitae congue mauris. Euismod nisi porta lorem mollis aliquam. Erat velit scelerisque in dictum. Scelerisque eleifend donec pretium vulputate. Id velit ut tortor pretium viverra suspendisse potenti. Enim neque volutpat ac tincidunt vitae semper quis lectus nulla. Lectus quam id leo in vitae turpis massa. Ac turpis egestas integer eget aliquet nibh praesent tristique. Cursus sit amet dictum sit.
In hac habitasse platea dictumst vestibulum. Orci sagittis eu volutpat odio facilisis. Aliquet risus feugiat in ante metus dictum. Faucibus in ornare quam viverra orci sagittis. Elementum nisi quis eleifend quam adipiscing vitae. Purus gravida quis blandit turpis cursus. Eget sit amet tellus cras adipiscing enim. Dolor sed viverra ipsum nunc aliquet bibendum. Ultrices in iaculis nunc sed augue lacus. Fames ac turpis egestas sed tempus urna et pharetra pharetra. Facilisis gravida neque convallis a cras. Risus sed vulputate odio ut enim blandit volutpat maecenas volutpat. Sit amet consectetur adipiscing elit pellentesque habitant. Ac turpis egestas integer eget aliquet. Dictumst quisque sagittis purus sit amet volutpat consequat mauris nunc. Consequat interdum varius sit amet mattis vulputate enim nulla aliquet. Vel facilisis volutpat est velit egestas dui id ornare. Velit sed ullamcorper morbi tincidunt ornare massa eget. Facilisis sed odio morbi quis commodo odio aenean sed adipiscing.`;
