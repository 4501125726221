import { urlBaseApi } from '../consts';

const urlBase = urlBaseApi + '/seminar-sections';

export const remove = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'DELETE',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const create = (token, body) => {
  return {
    url: `${urlBase}/`,
    method: 'POST',
    data: body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const update = (token, id, body) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'PUT',
    data: body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const one = (token, id) => {
  return {
    url: `${urlBase}/${id}`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

export const listMediaItems = (token, id) => {
  return {
    url: `${urlBase}/${id}/seminar-media`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};
