import React, { useContext, useState, useCallback } from 'react';
import UserContext from 'app/context/UserContext';
import {
  Modal,
  Button,
  CloseButton,
  Spinner,
  Row,
  Col,
  Form,
  Alert
} from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
import { create } from 'app/api/ContentService';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { minWidthBannerImage } from 'configServer';

const ModalUpload = ({ showModal, handlerShowModal, updateTable }) => {
  const [loading, setLoading] = useState(false);
  const [bannerImages, setBannerImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({
    bannerImage: [],
    file: []
  });

  const onDropFiles = useCallback(
    acceptedFiles => {
      setFiles([...acceptedFiles]);
      setErrors({
        ...errors,
        file: []
      });
    },
    [files]
  );

  const onDropBannerImage = useCallback(
    acceptedFiles => {
      setBannerImages([...acceptedFiles]);
      setErrors({
        ...errors,
        bannerImage: []
      });
    },
    [bannerImages]
  );

  const { user } = useContext(UserContext);
  const { getRootProps: getRootPropsFile, getInputProps: getInputPropsFile } =
    useDropzone({
      onDrop: onDropFiles
    });

  const {
    getRootProps: getRootPropsBannerImage,
    getInputProps: getInputPropsBannerImage
  } = useDropzone({
    onDrop: onDropBannerImage
  });

  const file = files.map(file => (
    <>
      {file.path} - {file.size} bytes{' '}
      <FontAwesomeIcon onClick={() => removeFile()} icon="times" />
    </>
  ));

  const bannerImage = bannerImages.map(file => (
    <>
      {file.path} - {file.size} bytes{' '}
      <FontAwesomeIcon onClick={() => removeFileBanner()} icon="times" />
    </>
  ));

  const errorsAlert = type => {
    return errors[type].map(item => (
      <Alert key={item.msg} variant="danger">
        {item.msg}
      </Alert>
    ));
  };

  function removeFileBanner() {
    setBannerImages([]);
    setErrors({
      ...errors,
      bannerImage: []
    });
  }

  function removeFile() {
    setFiles([]);
    setErrors({
      ...errors,
      file: []
    });
  }

  function removeAllFiles() {
    setBannerImages([]);
    setFiles([]);
  }

  function handlerSave() {
    setLoading(true);
    setErrors({
      bannerImage: [],
      file: []
    });

    const formData = new FormData();
    formData.append('file', files[0]);

    if (bannerImages.length) {
      formData.append('bannerImage', bannerImages[0]);
    }

    axios
      .request(create(user.token, formData))
      .then(() => {
        updateTable();
        removeAllFiles();
        setLoading(false);
        toast.success(
          'Item saved successfully. You may add another one here.',
          {
            theme: 'colored'
          }
        );
      })
      .catch(error => {
        const errorsResponce = error.response.data.errors;
        const errorsArray = {
          bannerImage: [],
          file: []
        };

        errorsResponce.map(errorObject => {
          errorsArray[errorObject.param] = [
            ...errorsArray[errorObject.param],
            errorObject
          ];
        });

        setErrors(errorsArray);
        setLoading(false);
      });
  }

  function handlerShowingModal() {
    removeAllFiles();
    setErrors({
      bannerImage: [],
      file: []
    });
    handlerShowModal();
  }

  return (
    <Modal
      show={showModal}
      onHide={() => handlerShowingModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Content Item
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => handlerShowingModal()}
        />
      </Modal.Header>
      <Modal.Body>
        <Form.Label>Item file (video/audio/image)</Form.Label>
        <div {...getRootPropsFile({ className: 'dropzone-area py-6' })}>
          <input {...getInputPropsFile({ multiple: false })} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-0 mb-0 text-700">Drop your file here</p>
          </Flex>
        </div>
        <div
          className={classNames('mt-3', {
            'text-danger': errors.file.length,
            'mb-2': errors.file.length,
            'mb-3': !errors.file.length
          })}
        >
          {files.length > 0 && <>{file}</>}
        </div>

        <div className="mb-3">
          {errors.file.length > 0 && <>{errorsAlert('file')}</>}
        </div>

        {/* <Form.Label>Banner Image(if needed)</Form.Label> */}
        <div
          {...getRootPropsBannerImage({
            className: 'dropzone-area py-6 d-none'
          })}
        >
          <input {...getInputPropsBannerImage({ multiple: false })} />
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-0 mb-0 text-700">Drop your file here</p>
          </Flex>
          <Flex justifyContent="center">
            <p className="fs-0 mb-0 text-500">
              Minimum image width: {minWidthBannerImage}px
            </p>
          </Flex>
        </div>
        <div
          className={classNames('mt-3 mb-3', {
            'text-danger': errors.bannerImage.length,
            'mb-2': errors.bannerImage.length,
            'mb-3': !errors.bannerImage.length
          })}
        >
          {bannerImages.length > 0 && <>{bannerImage}</>}
        </div>

        <div className="mb-3">
          {errors.bannerImage.length > 0 && <>{errorsAlert('bannerImage')}</>}
        </div>

        {!loading ? (
          <Row>
            <Col>
              <Button
                type="submit"
                disabled={!files.length}
                onClick={() => handlerSave()}
                variant="success"
              >
                Save
              </Button>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                onClick={() => handlerShowingModal()}
                variant="outline-primary"
              >
                Close
              </Button>
            </Col>
          </Row>
        ) : (
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span>Loading...</span>
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

ModalUpload.propTypes = {
  showModal: PropTypes.bool,
  handlerShowModal: PropTypes.func,
  updateTable: PropTypes.func
};

export default ModalUpload;
