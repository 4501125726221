import { getTotalViews } from 'app/api/MemberService';
import UserContext from 'app/context/UserContext';
import useRequest from 'app/hooks/useRequest';
import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

export default function TotalViews() {
  const { user } = useContext(UserContext);
  const params = useParams();
  const { response, sendData: fetchViews } = useRequest();
  const [total, setTotal] = useState('-');

  useEffect(() => {
    !response && fetchViews(getTotalViews(user.token, params.id));
  }, []);

  useEffect(() => {
    if (response) {
      setTotal(response.data.total);
    }
  }, [response]);

  return (
    <Card className="h-100">
      <Card.Header>
        <h5>Commitments</h5>
      </Card.Header>
      <Card.Body className="border-top">
        <p className="fw-semi-bold mb-1">Total views made: {total}</p>
      </Card.Body>
    </Card>
  );
}
