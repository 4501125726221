import 'helpers/initFA';

import App from 'app/App';
import Main from './Main';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <Main>
    <App />
  </Main>,
  document.getElementById('main')
);
