/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import useRequest from 'app/hooks/useRequest';
import { update as memberUpdateConfig } from 'app/api/MemberService';
import UserContext from 'app/context/UserContext';
import { toast } from 'react-toastify';

const MemberEditForm = ({ member, refreshMember }) => {
  const { user } = useContext(UserContext);
  const { send: updateRequest, isLoaded } = useRequest();

  const [formData, setFormData] = useState({
    balance: ''
  });

  useEffect(() => {
    setFormData({
      balance: member.balance
    });
  }, []);

  useEffect(() => {
    isLoaded &&
      toast.success(`Successfully updated`, {
        theme: 'colored'
      });

    refreshMember();
  }, [isLoaded]);

  const updateMember = () => {
    updateRequest(memberUpdateConfig(user.token, member.id, formData));
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    updateMember();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formGroupBalance">
        <Form.Label>Balance</Form.Label>
        <Form.Control
          name="balance"
          value={formData.balance ?? 0}
          onChange={handleChange}
          type="text"
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
};

export default MemberEditForm;
