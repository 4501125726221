import React, { useMemo, useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import useRequest from 'app/hooks/useRequest';
import { useParams } from 'react-router-dom';
import UserContext from 'app/context/UserContext';
import { one, listSeminarSectionsById } from 'app/api/SeminarsService';
import SeminarUpdateForm from './SeminarUpdateForm';

const SeminarUpdate = () => {
  const params = useParams();
  const { user } = useContext(UserContext);
  const { response: responseSeminar, sendData: sendDataSeminar } = useRequest();
  const { response: responseItemMedia, sendData: sendDataItemMedia } =
    useRequest();

  useEffect(() => {
    sendResponseSeminar();
    sendResponseItemMedia();
  }, [params.id]);

  const seminar = useMemo(() => {
    return responseSeminar?.data ? responseSeminar?.data : [];
  }, [responseSeminar]);

  function sendResponseSeminar() {
    const config = one(user.token, params.id);
    sendDataSeminar(config);
  }

  const itemMedia = useMemo(() => {
    return responseItemMedia?.data ? responseItemMedia?.data : [];
  }, [responseItemMedia]);

  function sendResponseItemMedia() {
    const config = listSeminarSectionsById(user.token, params.id);
    sendDataItemMedia(config);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Seminar Update ${seminar.title} | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <SeminarUpdateForm
        seminarOld={seminar}
        seminarSelectionItemOld={itemMedia}
        sendSeminarSlectionItemOld={sendResponseItemMedia}
      />
    </>
  );
};

export default SeminarUpdate;
