import React from 'react';
import Helmet from 'react-helmet';
import SeminarsCards from './SeminarsCards';

const Seminars = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Seminars | ${process.env.REACT_APP_PROJECT_NAME}`}</title>
      </Helmet>

      <SeminarsCards />
    </>
  );
};

export default Seminars;
