import { urlBaseApi as apiUrl } from '../consts';

const endpointUrl = apiUrl + '/uploads';

export const upload = (token, body) => {
  return {
    url: `${endpointUrl}`,
    method: 'POST',
    data: body,
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};
