import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';

import BadgeMemberStatus from 'app/components/common/BadgeMemberStatus';
import Date from 'app/components/common/Date';
import Tether from 'app/components/common/Tether';

const Info = ({ member }) => {
  const membership = member.MemberSubscription || null;

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5 className="mb-0">Details</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-light border-top">
        <Row>
          <Col lg xxl={5}>
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Account Information
            </h6>

            <Row key="1">
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Name</p>
              </Col>
              <Col>
                <p>{member.name}</p>
              </Col>
            </Row>

            <Row key="2">
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Mail</p>
              </Col>
              <Col>
                <p>
                  <a href={`mailto:${member.email}`}>{member.email}</a>
                </p>
              </Col>
            </Row>

            <Row key="3">
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Status</p>
              </Col>
              <Col>
                <span className="mb-3 d-block">
                  <BadgeMemberStatus status={member.status} />
                </span>
              </Col>
            </Row>

            <Row key="4">
              <Col xs={5} sm={4}>
                <p className="fw-semi-bold mb-1">Balance</p>
              </Col>
              <Col>
                <p>
                  {member.balance} <Tether />
                </p>
              </Col>
            </Row>
          </Col>

          <Col lg xxl={{ span: 5, offset: 1 }} className="mt-4 mt-lg-0">
            <h6 className="fw-semi-bold ls mb-3 text-uppercase">
              Membership Information
            </h6>

            {membership ? (
              <>
                <Row key="1">
                  <Col xs={5} sm={4}>
                    <p className="fw-semi-bold mb-1">Level</p>
                  </Col>
                  <Col>
                    <p>{membership.membershipLevel}</p>
                  </Col>
                </Row>

                <Row key="2">
                  <Col xs={5} sm={4}>
                    <p className="fw-semi-bold mb-1">Paid Date</p>
                  </Col>
                  <Col>
                    <p>
                      <Date date={membership.startedAt} format="human-time" />
                    </p>
                  </Col>
                </Row>

                <Row key="3">
                  <Col xs={5} sm={4}>
                    <p className="fw-semi-bold mb-1">Amount Paid</p>
                  </Col>
                  <Col>
                    <p>
                      {membership.amount} <Tether />
                    </p>
                  </Col>
                </Row>

                <Row key="4">
                  <Col xs={5} sm={4}>
                    <p className="fw-semi-bold mb-1">Receipt No.</p>
                  </Col>
                  <Col>
                    <p>{membership.receiptNumber}</p>
                  </Col>
                </Row>
              </>
            ) : (
              'No membersip yet'
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

Info.propTypes = {
  member: PropTypes.any
};

export default Info;
