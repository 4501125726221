import React from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/common/PageHeader';
import Common from './common/Common';

export default function Membership() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | Memberships</title>
      </Helmet>

      <PageHeader title="Membership Settings" className="mb-3"></PageHeader>

      <Common />
    </>
  );
}
