import React from 'react';
import { Card } from 'react-bootstrap';
import InvestmentForm from './InvestmentForm';

export default function Investment() {
  return (
    <>
      <Card className="h-100">
        <Card.Header className="bg-light btn-reveal-trigger d-flex flex-between-center">
          <h5 className="mb-0">Investment</h5>
        </Card.Header>
        <Card.Body>
          <InvestmentForm />
        </Card.Body>
      </Card>
    </>
  );
}
